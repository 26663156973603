import React, { Component } from 'react';
import { Autocomplete, Box, Button, Stack, Tooltip, Typography, TextField, Drawer, FormControl, InputLabel, Select, MenuItem, Table, TableCell } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import Loader from '../../../Components/Loader';
import { DateTime } from 'luxon';
import { formatDate } from '../../../Components/CommonFunctions/CommonFunctions';
import { CommonGridToolBarWithFilterText, CommonPatientDetails } from '../../../Components/Common Components/CommonComponents';
import CommonDatePicker from '../../../Components/Common Components/CommonDatePicker';
import { AmountsCard } from '../../../Components/CommonCards/CommonCards';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';

class LabResultCensusReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            censusReportData: [],
            groupData: [],
            testNameData: [],
            groupName: null,
            testName: null,
            FilterOpen: false,
            total_negative: 0,
            total_positive: 0,
            total_others: 0,
            disableBtn: false,
            patientName: '',
            resultType: '',
        }
    }

    componentDidMount() {
        this.getGroupNameList()
        this.getTestNameList()
        this.getCensusPositiveReport()
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    removeDuplicate(data) {
        let nameListJsonObject = data.map(JSON.stringify);
        let nameListUniqueSet = new Set(nameListJsonObject);
        let nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
        return nameListUniqueArray;
    }

    getGroupNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_GROUP_GET)
                .then((response) => {
                    if (response.data.status === 'success' && response?.data?.data?.length > 0) {
                        this.setState({
                            groupData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        }, () => console.log(this.state.groupData))
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error?.message)
        }
    }

    getTestNameList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_TEST_CONFIG_GET + "?only_test_name=true")
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            testNameData: response.data?.data?.length > 0 ? this.removeDuplicate(response.data?.data) : []
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error?.response?.data?.message)
                    } else {
                        this.errorMessage(error?.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getCensusPositiveReport = () => {
        try {
            let { groupName, testName, patientName, resultType } = this.state;
            let grp_name = groupName?.name ? groupName?.name : "";
            let test_name = testName?.test_name ? testName?.test_name : "";
            this.LoaderFunction(true);
            this.setState({ disableBtn: true })
            RestAPIService.getAll(`${Serviceurls.POSITIVE_CENSUS_REPORT}?grp_name=${grp_name}&test_name=${test_name}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&patient_name=${patientName}&result=${resultType}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        let Data = response?.data?.data;
                        this.LoaderFunction(false);
                        this.setState({
                            censusReportData: Data?.result?.length > 0 ? Data?.result : [],
                            total_positive: Data?.positive_count ? Data?.positive_count : 0,
                            total_negative: Data?.negative_count ? Data?.negative_count : 0,
                            total_others: Data?.others_count ? Data?.others_count : 0,
                            disableBtn: false,
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.LoaderFunction(false);
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.LoaderFunction(false);
                        this.errorMessage(error.message)
                    }
                    this.setState({ disableBtn: false })
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({ disableBtn: false })
        }
    }

    gridToolBar = () => {
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.fromDate) },
            { label: "To Date", value: formatDate(this.state.toDate) },
        ]
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }


    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        })
    }

    closeFilter = () => {
        this.setState({
            FilterOpen: false
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            HideAllMenu={true} />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.groupData?.length > 0 ? this.state.groupData : []}
                            getOptionLabel={(option) => option?.name}
                            value={this.state.groupName ? this.state.groupName : null}
                            onChange={(e, newValue) => {
                                this.setState({ groupName: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Group Name' />)}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            clearIcon
                            options={this.state.testNameData?.length > 0 ? this.state.testNameData : []}
                            getOptionLabel={(option) => option?.test_name}
                            value={this.state?.testName ? this.state?.testName : null}
                            onChange={(e, newValue) => {
                                this.setState({ testName: newValue })
                            }}
                            sx={{ width: '20vw' }}
                            renderInput={(params) => (<TextField {...params} label='Select Test Name' />)}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                            sx={{ width: '20vw' }}
                            size='small'
                            label={t('Patient Name')}
                            value={this.state.patientName}
                            autoComplete='off'
                            onChange={(e) => {
                                let number = CommonValidation.ALPHA_NUM_SPLCHAR(e.target.value);
                                if (number || e.target.value === "") {
                                    this.state.patientName = e.target.value.toUpperCase()
                                    this.setState({ patientName: this.state.patientName })
                                }
                            }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <FormControl size='small' sx={{ width: '20vw' }}>
                            <InputLabel id="demo-simple-select-label">Select Result Type</InputLabel>
                            <Select
                                value={this.state.resultType}
                                label="Select Result Type"
                                onChange={(e)=>{
                                    this.setState({
                                        resultType: e.target.value
                                    })
                                }}
                            >
                                <MenuItem value={"Positive"}>{"Positive"}</MenuItem>
                                <MenuItem value={"Negative"}>{"Negative"}</MenuItem>
                                <MenuItem value={"Others"}>{"Others"}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" className='eMed_rts_btn' alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"
                        onClick={() => {
                            this.setState({
                                groupName: null,
                                testName: null,
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                patientName: '',
                                resultType: '',
                            },
                                () => this.getCensusPositiveReport())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid='Census_Report_FilterSearch_btn' disabled={this.state.disableBtn}
                        onClick={() => {
                            this.getCensusPositiveReport()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    render() {
        this.state.censusReportData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            { field: "sno", sortable: false, flex: 0.05, headerName: t("SNo") },
            {
                field: "date", headerName: t("Date"), flex: 0.12, align: 'center', headerAlign: 'center',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.date ? params?.row?.date : '-'}</Box>)
            },
            {
                field: "patient_name", headerName: t("Patient Details"), flex: 0.25, type:'string',
                renderCell: (params) => (<CommonPatientDetails data={params?.row} disablePhoto={true}/>)
            },
            {
                field: "grp_name", headerName: `${t("Group Name")}`, flex: 0.20, type: "string",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.grp_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.grp_name} arrow><div>{params?.row?.grp_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.grp_name ? params?.row?.grp_name : "-"}</Box>)
            },
            {
                field: "test_name", headerName: `${t("Test Name")}`, flex: 0.20, type: "string",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.test_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.test_name} arrow><div>{params?.row?.test_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.test_name ? params?.row?.test_name : "-"}</Box>)
            },
            {
                field: "normal_rang", headerName: `${t("Reference Range")}`, flex: 0.23, type: "string",
                renderCell: (params) => (
                ((params?.row?.normal_range?.length > 0) && Array.isArray(params?.row?.normal_range)) ? params?.row?.normal_range?.map((range, rangeIndex) => (
                        <Table key={rangeIndex}>
                            <TableCell sx={{ padding: '0vw', borderBottom: 'none' }}>
                                {(range?.reference_from + range?.reference_to + range?.unit)?.length > 10 ?
                                    <Tooltip placement='top' title={`${range?.reference_from ? range?.reference_from : ''} (${range?.unit ? range?.unit : ''}) to ${range?.reference_to ? range?.reference_to : ''} (${range?.unit ? range?.unit : ''})`}>
                                        <Typography fontSize={'0.8vw'} component={'div'}>{`${range?.reference_from ? range?.reference_from : ''} (${range?.unit ? range?.unit : ''}) to ${range?.reference_to ? range?.reference_to : ''} (${range?.unit ? range?.unit : ''})`}</Typography>
                                    </Tooltip> :
                                    <Typography fontSize={'0.8vw'}>{`${range?.reference_from ? range?.reference_from : ''} ${range?.unit ? "(" + range?.unit + ")" : ''} ${range?.unit ? "to" : "-"} ${range?.reference_to ? range?.reference_to : ''} ${range?.unit ? "(" + range?.unit + ")" : ''}`}</Typography>}
                            </TableCell>
                        </Table>
                    )) : '-'
                )
            },
            {
                field: "result", headerName: `${t("Result")}`, flex: 0.13, type: "string",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.result?.length > 12 ?
                    <Tooltip placement='top' title={params?.row?.result} arrow><div>{params?.row?.result.slice(0, 11) + "..."}</div></Tooltip>
                    : params?.row?.result ? params?.row?.result : "-"}</Box>
                )
            },
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("Positive"), this.state.total_positive, true)}
                        {AmountsCard(t("Negative"), this.state.total_negative, true)}
                        {AmountsCard(t("Others"), this.state.total_others, true)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} sx={{height: '66vh', width: '92vw', margin: 'auto', backgroundColor: 'white'}}>
                    <DataGrid
                        rows={this.state.censusReportData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.isLoader} />
            </Box>
        )
    }
}
export default withTranslation()(LabResultCensusReport)

