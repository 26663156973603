import {
  Autocomplete, Box, Button, Collapse, IconButton, Modal, Menu, MenuItem,
  Tab, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, Tooltip
} from '@mui/material'
import React, { Component } from 'react'
import ToastMsg from '../../../../../Components/ToastMsg/ToastMsg'
import { CommonPatientCard, CommonPatientDetails, DeletePopup, ToolsField } from '../../../../../Components/Common Components/CommonComponents'
import { Colors } from '../../../../../Styles/Colors'
import { ImagePaths } from '../../../../../Utility/ImagePaths'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { CheckAccessFunc } from '../../../../../Components/CommonFunctions/CommonFunctions'
import { getCachevalue, localGetItem, setCachevalue } from '../../../../../Utility/Services/CacheProviderService'
import RestAPIService from '../../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls'
import CreditBillCompletePoP from '../../../../FrontOffice/BillingTransaction/CreditBillCompletePoP'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CommonValidation from '../../../../../Components/CommonFunctions/CommonValidation'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
import FormatUnderlinedOutlinedIcon from '@mui/icons-material/FormatUnderlinedOutlined';
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import FormatAlignCenterOutlinedIcon from '@mui/icons-material/FormatAlignCenterOutlined';
import FormatAlignRightOutlinedIcon from '@mui/icons-material/FormatAlignRightOutlined';
import { Bucket_Name } from '../../../../../Utility/Constants'
import PrintPreviewPop from '../../../../../Components/Common Components/PrintPreviewPop'


let isUser = null;
let AWS = require('aws-sdk');
let s3 = new AWS.S3({
  signatureVersion: 'v4',
  accessKeyId: "AKIAT7TG52UHOEIYAJH4",
  secretAccessKey: "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",
  region: "ap-south-1",
  params: { Bucket: Bucket_Name },
});

export default class RadSavedBill extends Component {

  constructor(props) {
    super(props)
    this.state = {
      patientData: {},
      attender_mobile: '',
      attender_name: '',
      radBillsData: [],
      selectedIndex: null,
      templatePopUpOpen: false,
      selectedServiceData: null,
      selectedTemplateResult: '',
      templateData: [],
      selectedTemplate: null,
      isSave: false,
      creditBillPopUp: false,
      opCreditBill: {},
      makePayment: false,
      EditorDisable: false,
      ResultTextData : "",
      printPreviewPop: false,
      printPreviewData: '',
      pdfName: '',
      ResultPDF: [],
      confirmpopup: false,
      editedText: ''
    }
    this.editorRef = React.createRef();
  }

  componentDidMount() {
    isUser = JSON.parse(localGetItem('loggedInUserInfo'))
    let patientData = JSON.parse(getCachevalue('RadPatientData'))
    this.setState({
      patientData: patientData
    }, () => this.getRadPatientBillsData())
  }

  handleStyleChange = (command) => {
    document.execCommand(command, false, null);
  };

  getRadPatientBillsData = () => {
    try {
      let params = ''
      if (this.state.patientData?.ip_admission_id !== null && this.state.patientData?.ip_admission_id !== undefined) {
        params = `?admission_id=${this.state.patientData?.ip_admission_id}&patient_id=${this.state.patientData?.patient_id}`
      } else if (this.state.patientData?.appointment_id !== null && this.state.patientData?.appointment_id !== undefined) {
        params = `?appointment_id=${this.state.patientData?.appointment_id}&patient_id=${this.state.patientData?.patient_id}`
      } else {
        let date = this.state.patientData?.invoice_date?.slice(0, 10)?.split('-')
        let invoiceDate = date?.length > 0 ? date[2] + "-" + date[1] + "-" + date[0] : ""
        params = `?patient_id=${this.state.patientData?.patient_id}&bill_date=${invoiceDate}`
      }
      RestAPIService.getAll(Serviceurls.RAD_PATIENT_BILLS_GET + params)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              radBillsData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getResultTemplateData = () => {
    try {
      this.setState({ tempPost: true })
      RestAPIService.getAll(Serviceurls.RAD_RESULT_GET + '?service_type=' + this.state.selectedServiceData?.service_type + '&service_name=' + this.state.selectedServiceData?.service_name)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              templateData: response.data.data,
              selectedTemplate: this.state.selectedServiceData?.template_name ? this.state.selectedServiceData?.template_name : response.data.data[0],
              selectedTemplateResult: this.state.selectedServiceData?.result_data ? this.state.selectedServiceData?.result_data : response.data.data[0]?.template_data
            }, () => {
              this.setState({
                ResultTextData : this.state.selectedServiceData?.result_data ? this.state.selectedServiceData?.result_data : ""
              })
              // this.editorRef.current.innerHTML = this.state.selectedServiceData?.result_data ? this.state.selectedServiceData?.result_data : response.data.data[0]?.template_data ? response.data.data[0]?.template_data : ""
            })
          }
        }).catch((error) => {
          this.setState({ tempPost: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ tempPost: false })
      this.errorMessage(error.message)
    }
  }

  postResultTemplateData = (ResultData, forSave = false, isForCompleted = false, file) => {
    try {
      const data = new FormData();
      data.append('id', this.state.selectedServiceData?.id)
      data.append('result_data', ResultData ? ResultData : this.state.editedText ? this.state.editedText : "")
      data.append('update_status', forSave ? 'Result Saved' : isForCompleted ? 'Completed' : "Dispatched")
      data.append('template_name', this.state.selectedTemplate?.template_name ? this.state.selectedTemplate?.template_name : this.state.selectedTemplate || '')
      if (file?.length > 0) {
        if (this.state.ResultPDF?.length > 0 && this.state.ResultPDF[0]?.document_name === file[0]?.document_name) {
          data.append('result_document', JSON.stringify(this.state.ResultPDF))
        } else {
          data.append('result_document', file[0]?.document_file, file[0].document_name)
        }
      } else if (this.state.ResultPDF?.length > 0 && file?.length === 0) {
        data.append('result_document', "")
        data.append('is_deleted', true)
      } else {
        data.append('result_document', "")
      }

      RestAPIService.create(data, Serviceurls.RAD_RESULT_GET)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getRadPatientBillsData()
            this.setState({
              selectedServiceData: null,
              selectedTemplate: null,
              selectedTemplateResult: '',
              templatePopUpOpen: false,
              tempPost: false,
              isSave: false,
              EditorDisable: false,
              ResultTextData: "",
              confirmpopup: false,
              editedText: ''
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  creditBillPost = (message = "", error = false) => {
    this.setState({
      creditBillPopUp: false,
      makePayment: false
    }, () => {
      if (error) {
        this.errorMessage(message)
      } else {
        this.successMessage(message)
      }
      this.getRadPatientBillsData()
    })
  }

  ClosePopUp() {
    this.setState({
      creditBillPopUp: false,
      makePayment: false
    })
  }

  dispatchPopup = (value)=>{
    this.setState({
      confirmpopup : true,
      editedText: value
    })
  }

  PopupClose = () => {
    this.setState({
      confirmpopup: false,
      editedText: ''
    })
  }

  renderResultUploadPopup = () => {
    try {
      return (
        <Box component={'div'}>
          <Modal open={this.state.templatePopUpOpen}>
            <Box component={'div'} className='rad_popup_style'>
              <Box component={'div'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} mt={"0.5vw"} mr={'0.5vw'}>
                <Autocomplete
                  value={this.state.selectedTemplate}
                  options={this.state.templateData}
                  disabled={this.state.selectedServiceData?.result_status === 'Completed' ? true : false}
                  getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.template_name}
                  size='small'
                  onChange={(e, value) => {
                    this.setState({
                      selectedTemplate: value
                    }, () => {
                      if (this.state.selectedTemplate !== null) {
                        this.setState({
                          ResultTextData: this.state.selectedTemplate?.template_data
                        })
                        // this.editorRef.current.innerHTML = this.state.selectedTemplate?.template_data
                      } else {
                        this.setState({
                          ResultTextData: ""
                        })
                        // this.editorRef.current.innerHTML = ""
                      }
                    })
                  }}
                  sx={{ width: '20vw', mr: '2vw' }}
                  renderInput={(params) => <TextField {...params} label='Select Template' />}
                />
                <Button className='Common_Btn_Min_Width' emed_tid='rad_tmp_cls_btn'
                  onClick={() => this.setState({
                    templatePopUpOpen: false,
                    EditorDisable: false,
                    selectedServiceData: null,
                    selectedTemplateResult: '',
                    selectedTemplate: null
                  })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <ToolsField
                FromTestReport={true}
                EditorDisable={this.state.EditorDisable}
                Title={"Result"}
                EditorRef={this.state.ResultTextData}
                handleSaveResult={this.postResultTemplateData.bind(this)}
                handlePostResult={this.postResultTemplateData.bind(this)}
                handleDispatchResult={this.dispatchPopup.bind(this)}
                height={"59vh"} overallHeight={"72vh"} EditorHeight={"59vh"}
                errorMsg={this.errorMessage.bind(this)}
                selectedServiceData={this.state.selectedServiceData}
                ResultPDF={this.state.ResultPDF}
              />
              {/* <Box component={'div'} display={'flex'} justifyContent={'space-between'} height={'3vw'} alignItems={'center'} p={'0.5vw'} pt={'1vw'}>
                <Box component={'div'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} width={"70%"}>
                  <Typography ml={"0.5vw"} fontWeight={600}>Result</Typography>
                  <div className="toolbar" style={{ width: "45%" }}>
                    <Tooltip title={"Bold"} placement={'top'} arrow><FormatBoldIcon onClick={() => this.handleStyleChange('bold')} /></Tooltip>
                    <Tooltip title={"Italic"} placement={'top'} arrow><FormatItalicOutlinedIcon onClick={() => this.handleStyleChange('italic')} /></Tooltip>
                    <Tooltip title={"Underline"} placement={'top'} arrow><FormatUnderlinedOutlinedIcon onClick={() => this.handleStyleChange('underline')} /></Tooltip>
                    <Tooltip title={"Left"} placement={'top'} arrow><FormatAlignLeftOutlinedIcon onClick={() => this.handleStyleChange('justifyLeft')} /></Tooltip>
                    <Tooltip title={"Center"} placement={'top'} arrow><FormatAlignCenterOutlinedIcon onClick={() => this.handleStyleChange('justifyCenter')} /></Tooltip>
                    <Tooltip title={"Right"} placement={'top'} arrow><FormatAlignRightOutlinedIcon onClick={() => this.handleStyleChange('justifyRight')} /></Tooltip>
                  </div>
                </Box>
                <Box component={'div'} display={'flex'} mt={"0.5vw"}>
                  <Autocomplete
                    value={this.state.selectedTemplate}
                    options={this.state.templateData}
                    disabled={this.state.selectedServiceData?.result_status === 'Completed' ? true : false}
                    getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.template_name}
                    size='small'
                    onChange={(e, value) => {
                      this.setState({
                        selectedTemplate: value
                      }, () => {
                        if (this.state.selectedTemplate !== null) {
                          this.editorRef.current.innerHTML = this.state.selectedTemplate?.template_data
                        } else {
                          this.editorRef.current.innerHTML = ""
                        }
                      })
                    }}
                    sx={{ width: '15vw', mr: '1vw' }}
                    renderInput={(params) => <TextField {...params} label='Select Template' />}
                  />
                  <Button className='Common_Btn_Min_Width' emed_tid='rad_tmp_cls_btn'
                    onClick={() => this.setState({
                      templatePopUpOpen: false,
                      selectedServiceData: null,
                      selectedTemplateResult: '',
                      selectedTemplate: null
                    })}
                  >
                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                  </Button>
                </Box>
              </Box>
              <Box component={'div'} mt={'1vw'} style={{ width: "100%", display: "flex", alignItems: "center" }}>
                <Box component={'div'} ref={this.editorRef} contentEditable={this.state.selectedServiceData?.result_status === 'Completed' ? false : true} style={{ border: "1px solid gray", padding: "0.5vw", height: "50vh", overflow: "scroll", width: "98%", marginLeft: "0.75vw" }}></Box>
              </Box>
              <Box mt={'0.5vw'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'} visibility={this.state.selectedServiceData?.result_status === 'Completed' ? 'hidden' : 'visible'}>
                <Button variant='outlined' sx={{ textTransform: 'capitalize', mr: '1vw', height: '2vw' }} emed_tid='rad_tmp_cal_btn'
                  onClick={() => this.setState({ templatePopUpOpen: false, selectedServiceData: null })}
                >Cancel</Button>
                <Button variant='contained' sx={{ textTransform: 'capitalize', mr: '1vw', height: '2vw' }} emed_tid='rad_tmp_sav_btn'
                  onClick={() => { this.setState({ isSave: true }, () => this.postResultTemplateData()) }}
                >Save</Button>
                <Button variant='contained' sx={{ textTransform: 'capitalize', mr: '1vw', height: '2vw' }} emed_tid='rad_tmp_comp_btn'
                  onClick={() => { this.postResultTemplateData() }}
                >Complete</Button>
              </Box> */}
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  selectedOptions = (option, data) => {
    try {
      if (option?.value === 'ModifyBill') {
        setCachevalue(true, "isForCancelBill")
        setCachevalue(JSON.stringify({ fromModifyBill: true, billSummaryId: data?.bill_summary_id, ForCancel: true, AmountNet: data?.total_bill_amount, FromBills: true, isForEditBill: option?.value === "ModifyBill" }), "RadCancelBillData")
        this.props.history?.push({ pathname: '/RadiologyHome/Patient/Billing', state: { fromModifyBill: true, billSummaryId: data?.bill_summary_id, Reset:true } })
      } else if (option?.value === 'cancelBill') {
        let lastBillCancel = this.state.radBillsData?.length === 1 ? true : false
        setCachevalue(true, "isForCancelBill")
        setCachevalue(JSON.stringify({ fromCancelBill: true, billSummaryId: data?.bill_summary_id, ForCancel: true, AmountNet: data?.total_bill_amount, FromBills: true, isForEditBill: option?.value === "cancelBill", lastBillCancel: lastBillCancel }), "RadCancelBillData")
        this.props.history?.push({ pathname: '/RadiologyHome/Patient/Billing', state: { fromCancelBill: true, billSummaryId: data?.bill_summary_id, Reset:true } })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  MenuItem = (data) => {
    const { t } = this.props
    let RoleData = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
    const multi_option = [
      { value: "ModifyBill", label: ("Modify Bill") },
      { value: "cancelBill", label: ("Cancel Bill") },
      // { value: "ModifyHistory", label: ("Modify History") },
    ]

    if (data?.payment_status !== 'not paid') {
      multi_option?.splice(0, 1)
    }
    return (
      <div>
        <Tooltip title={("More")} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.invoice_number,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.invoice_number) ?
          <Menu
            key={data?.invoice_number}
            elevation={1}
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null, })
            }}>
            {multi_option.map((option, index) => (
              <MenuItem key={index} emed_tid={`${option?.value}`}
                onClick={() => {
                  this.setState({
                    billSummaryId: data?.bill_summary_id
                  }, () => this.selectedOptions(option, data))
                }}
              >
                {(option.label)}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  getS3Key(doc_key, doc_name) {
    new Promise((resolve, reject) => {
      const params = {
        Bucket: Bucket_Name,
        Key: doc_key,
        Expires: 300,
        ResponseContentType: 'application/pdf', // Ensure correct content type
        ResponseContentDisposition: 'inline',  // This ensures the file is opened in the browser
      };

      s3.getSignedUrl('getObject', params, (err, url) => {
        if (err) {
          reject(err);
        } else {
          this.setState({
            printPreviewPop: true,
            printPreviewData: url,
            pdfName: doc_name ? doc_name : 'PDF Preview'
          });
          resolve(url);
        }
      });
    })
  }

  moreActionOption = (data) => {
    let multi_option = []
    multi_option.push({ label: "View Document", value: "viewDocument" })
    return (
      <div>
        <Tooltip title={"More"} placement='top' arrow>
          <IconButton
            onClick={(e) => {
              this.setState({
                anchorEl: e.currentTarget,
                selectedRow: data.id,
                isSelected: true
              })
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        {(this.state.isSelected && this.state.selectedRow == data.id) ?
          <Menu
            anchorEl={this.state.anchorEl}
            open={Boolean(this.state.anchorEl)}
            onClose={() => {
              this.setState({ anchorEl: null })
            }}
          >
            {multi_option.map((option) => (
              <MenuItem key={option}
                disabled={!(data?.result_document?.length > 0)}
                onClick={() => { this.getS3Key(data?.result_document[0]?.document_file, data?.result_document[0]?.document_name) }}>
                {option.label}
              </MenuItem>
            ))}
          </Menu> : null}
      </div>
    )
  }

  closePrintPreview = () => {
    this.setState({
      printPreviewPop: false,
      printPreviewData: '',
      pdfName: ''
    })
  }

  fetcheditorRef = (secItem) => {
    setTimeout(() => {
      this.setState({
        ResultTextData : secItem?.result_data ? secItem?.result_data : ""
      })
      // this.editorRef.current.innerHTML = secItem?.result_data
    }, 1000);
  }

  renderSecondTable = (item, index) => {
    try {
      item?.service_details?.forEach((element, index) => element.sno = index + 1)
      return (
        <><TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={this.state.selectedIndex === index} timeout={'auto'}>
            <Box margin={1} className='rad_lft_scd_tbl'>
              <Table size='small' stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={600} fontSize={'0.9vw'}>S.No</Typography>
                    </TableCell>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={600} fontSize={'0.9vw'}>Service Type</Typography>
                    </TableCell>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={600} fontSize={'0.9vw'}>Service Name</Typography>
                    </TableCell>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={600} fontSize={'0.9vw'}>Result Status</Typography>
                    </TableCell>
                    <TableCell className='rad_rgt_tbl_head'>
                      <Typography fontWeight={600} fontSize={'0.9vw'}>Action</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item?.service_details?.length > 0 ? item?.service_details?.map((secItem, secIndex) => (
                    <TableRow key={secIndex}>
                      <TableCell>
                        <Typography>{secItem?.sno}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{secItem?.service_type}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{secItem?.service_name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>{secItem?.result_status}</Typography>
                      </TableCell>
                      <TableCell>
                        <Box component={'div'} display={'flex'} alignItems={'center'}>
                          {((secItem?.result_status === 'In Progress') || (secItem?.result_status === 'Result Saved')) ?
                            <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw' }} emed_tid='rad_bill_upl'
                              disabled={!CheckAccessFunc('Radiology', "Home", 'Billing', 'Bills', "SubTab")?.editAccess}
                              onClick={() => this.setState({ templatePopUpOpen: true, selectedServiceData: secItem, ResultPDF: secItem?.result_document ? secItem?.result_document : [] }, () => this.getResultTemplateData())}
                            >
                              <Box component={'img'} src={ImagePaths.UploadBtnTheme.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button> :
                            <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw' }} emed_tid='rad_bill_view'
                              onClick={() => this.setState({
                                selectedTemplate: secItem?.template_name,
                                selectedTemplateResult: secItem?.template_data,
                                selectedServiceData: secItem,
                                ResultPDF: secItem?.result_document ? secItem?.result_document : []
                              }, () => {
                                this.setState({ templatePopUpOpen: true, EditorDisable : true }, () => { this.fetcheditorRef(secItem) })
                              })}
                              disabled={!CheckAccessFunc('Radiology', "Home", 'Billing', 'Bills', "SubTab")?.editAccess}>
                              <Box component={'img'} src={ImagePaths.LabViewButton.default} height={'1.5vw'} width={'1.5vw'} />
                            </Button>
                          }
                          {this.moreActionOption(secItem)}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )) :
                    <TableRow>
                      <TableCell align='center' colSpan={5}>No Records To Be Shown</TableCell>
                    </TableRow>}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell></>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  GetBillPrint = (id) => {
    try {
      this.setState({ disableBtn: true })

        RestAPIService.getAllPrint(Serviceurls.RAD_BILL_POST + '?bill_summary_id=' + id + "&export_type=pdf").
            then((response) => {
              this.setState({ disableBtn: false })
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                this.setState({ disableBtn: false })
            }).catch((error) => {
                this.errorMessage(error.response?.data?.message, false);
            })
    } catch (error) {
        this.errorMessage(error.message, false)
    }
}

  render() {
    let data = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.attender_name ? this.state.attender_name : "-"}`,
      lineFour: "Contact Number",
      LineFive: `${this.state.attender_mobile ? this.state.attender_mobile : "-"}`
    }
    let Module_ID_Key_Name = ''; let Module_ID_Key_Value = '';
    let Module_ID_Key_Name_Two = ''; let Module_ID_Key_Value_Two = '';
    if (this.state.patientData?.ip_admission_id !== null && this.state.patientData?.ip_admission_id !== undefined) {
      Module_ID_Key_Name = "ip_admission_id"
      Module_ID_Key_Value = this.state.patientData?.ip_admission_id
    } else if (this.state.patientData?.appointment_id !== null && this.state.patientData?.appointment_id !== undefined) {
      Module_ID_Key_Name = "appointment_id"
      Module_ID_Key_Value = this.state.patientData?.appointment_id
    } else {
      if (this.state.makePayment) {
        let date = this.state.patientData?.invoice_date?.slice(0, 10)?.split('-')
        let invoiceDate = date[2] + "-" + date[1] + "-" + date[0]

        Module_ID_Key_Name = "patient_id"
        Module_ID_Key_Value = this.state.patientData?.patient_id
        Module_ID_Key_Name_Two = 'bill_date'
        Module_ID_Key_Value_Two = invoiceDate
      } else {
        Module_ID_Key_Name = "bill_id"
        Module_ID_Key_Value = this.state.patientData?.bill_summary_id
      }
    }
    let visibility = this.props?.history?.location?.state?.fromCancelBill || this.props?.history?.location?.state?.fromModifyBill
    return (
      <Box component={'div'}>
        <Box component={'div'} sx={{ backgroundColor: Colors.Background, height: '73vh' }}>
          <Box component={'div'} height={"9.5vh"}>
            <Box component={'div'} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
              <Box component={'div'} display={'flex'}>
                <Box component={'div'} className='eMed_nur_notes_details' ml={'0.5vw'}>
                  <CommonPatientDetails data={this.state.patientData} />
                </Box>
                {/* <CommonPatientCard details={data} showDetailed={true} /> */}
              </Box>
              {/* <Box component={'div'} display={'flex'} mr={'2vw'} visibility={visibility ? 'hidden' : 'visible'}>
                <Button variant='contained' sx={{ height: '2vw', textTransform: 'capitalize' }} emed_tid='rad_bill_mak_pay'
                  onClick={() => this.setState({ makePayment: true, creditBillPopUp: true })}>Make Payment</Button>
              </Box> */}
            </Box>
          </Box>
          <Box component={'div'} border={'1px solid lightgray'} m={'0.5vw'} height={'62vh'} overflow={'auto'} sx={{ backgroundColor: Colors.white }}>
            <Table stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Bill Number</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>No Of Services</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Payment Status</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Total Bill Amount</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Total Paid Amount</Typography>
                  </TableCell>
                  <TableCell className='rad_bill_tbl_head' align='center'>
                    <Typography fontWeight={600} color={Colors.SecondaryText}>Action</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.radBillsData?.length > 0 ? this.state.radBillsData?.map((item, index) => (
                  <><TableRow key={index}>
                    <TableCell><Typography>{item?.invoice_number ? item?.invoice_number : '-'}</Typography></TableCell>
                    <TableCell><Typography>{item?.no_of_service ? item?.no_of_service : '-'}</Typography></TableCell>
                    <TableCell>
                      <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.bill_type ? CommonValidation.capitalizeFirstLetter(item?.bill_type) : '-'}</Typography>
                      <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{item?.payment_status ? CommonValidation.capitalizeFirstLetter(item?.payment_status) : '-'}</Typography>
                    </TableCell>
                    <TableCell><Typography>{item?.total_bill_amount ? item?.total_bill_amount : 0}</Typography></TableCell>
                    <TableCell><Typography>{item?.total_received_amount ? item?.total_received_amount : 0}</Typography></TableCell>
                    <TableCell>
                      <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ visibility: visibility ? 'hidden' : 'visible' }}>
                        <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw' }} emed_tid='rad_bill_prt' disabled={this.state.disableBtn} onClick={()=>{this.GetBillPrint(item?.bill_summary_id)}}>
                          <Box component={'img'} src={ImagePaths.PrintIcons.default} height={'1.5vw'} width={'1.5vw'} />
                        </Button>
                        <Button className='Common_Btn_Min_Width' sx={{ height: '1.5vw', visibility: (item?.payment_status === "not paid" || item?.payment_status === "partial") ? 'visible' : 'hidden' }} emed_tid='rad_bill_view'
                          onClick={() => this.setState({ creditBillPopUp: true, opCreditBill: item })}
                        >
                          <Box component={'img'} src={ImagePaths.CreateBill.default} height={'1.5vw'} width={'1.5vw'} />
                        </Button>
                        {this.MenuItem(item, index)}
                        <IconButton emed_tid='rad_srv_lft_drp'
                          size='small'
                          onClick={() => {
                            this.setState({ selectedIndex: this.state.selectedIndex === index ? null : index })
                          }}
                        >
                          {this.state.selectedIndex === index ?
                            <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                    <TableRow>
                      {this.renderSecondTable(item, index)}
                    </TableRow>
                  </>
                )) :
                  <TableRow>
                    <TableCell colSpan={7} align='center'>No Records To Be Shown</TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </Box>
        </Box>
        {this.renderResultUploadPopup()}
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.creditBillPopUp ?
          <CreditBillCompletePoP
            Module_ID_Key_Name={"bill_id"}
            Module_ID_Key_Value={this.state.opCreditBill?.bill_summary_id}
            modules={['radiology']}
            CrebillPosted={this.creditBillPost}
            BillData={this.state.patientData}
            ClosePopUp={this.ClosePopUp.bind(this)}
            CollectedIn={"RADIOLOGY"}
            Module_ID_Key_Value_Two={Module_ID_Key_Value_Two}
            Module_ID_Key_Name_Two={Module_ID_Key_Name_Two}
          /> : null}
        {this.state.printPreviewPop ?
          <PrintPreviewPop
            Data={this.state.printPreviewData}
            Title={this.state.pdfName}
            BillData={this.state.selectedRow}
            ClosePopUp={() => { this.closePrintPreview() }}
            history={this.props.history}
          /> : null}
        {this.state.confirmpopup ?
          <DeletePopup DeletTitle={"Are you sure you want to dispatch ?"}
            deleteAlertPopupClose={this.PopupClose.bind(this)}
            removeData={this.postResultTemplateData.bind(this)}
            ButtonText={"Confirm"}
          />
          : null}
      </Box>
    )
  }
}
