import {
    Button, Table, TableCell,
    TableContainer, TableHead, TableRow,
    TableBody, Modal, Typography, Box, Select,
    MenuItem, TextField, IconButton,
    FormControl, InputLabel, Tooltip, Autocomplete,
    Menu, FormGroup, FormControlLabel, Checkbox
} from '@mui/material';
import React, { Component } from 'react'
import './home.css';
import { ImagePaths } from '../../../Utility/ImagePaths'
import { Trans, withTranslation } from 'react-i18next';
import ImageUploading from 'react-images-uploading';
import { Close } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import { t } from 'i18next';
import { Colors } from '../../../Styles/Colors';
import { CommonDeleteButton, CommonEditButton } from '../../../Components/Common Components/CommonComponents';
import { Bucket_Name } from '../../../Utility/Constants';
import TestHistory from './TestHistory';

var AWS = require('aws-sdk');
var s3 = new AWS.S3({
    accessKeyId: "AKIAT7TG52UHOEIYAJH4",
    secretAccessKey: "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",
    region: "ap-south-1"
});

const PosiNega = [
    { result_name: "Positive", value: "Positive" },
    { result_name: "Negative", value: "Negative" },
    { result_name: "Others", value: "Others" }
]

const PresAbsen = [
    { result_name: "Present", value: "Present" },
    { result_name: "Absent", value: "Absent" },
    { result_name: "Others", value: "Others" }
]

class Result extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patient_status: {},
            patientResultStatus: [],
            methodOneResultText: "",
            methodOneResultTextUnit: "",
            selectDropDownValue: "",
            methodThreeResult: [],
            methodThreeBacteriaResult: "",
            antibacteria: null,
            bacteria: null,
            Interpretation: "",
            autoZone: "",
            zoneResult: "",
            breakPoint: "",
            breakPointResult: "",
            selectedSubIndex: null,
            page: 0,
            rowsPerPage: 10,
            methodFourPara: [],
            methodFourImpress: [],
            metodFourTable: [],
            methodFourResult: [],
            methodFourData: [],
            method: [],
            bacteriaList: [],
            antiBacteriaList: [],
            isErrorMsg: false,
            isErrorMsgText: "",
            checkList: [],
            deleteData: true,
            uploadedImages: [],
            selectedImage: {},
            selectedImageName: [],
            deleteDataList: [],
            selectedDeletedIndex: [],
            selectedImageIndex: null,
            selectedMethodThreeMainIndex: '',
            selected_save_data: null,
            selected_parse_data: null,
            selectedImageRowId: null,
            SaveChangesClick: false,
            showPrintBtn: false,
            microscopy: "",
            colonyCount: "",
            EditCulture: false,
            EditedIndex: "",
            EditedSubIndex: "",
            signatureList: [],
            ActiveDropDown: [],
            disableLeft: false,
            disableCentre: false,
            disableRight: false,
            LeftDrop: null,
            CentreDrop: null,
            RightDrop: null,
            viewPrintLst: false,
            PrintTestList: [],
            isPrintClicked: false,
            checkboxState: [],
            cul_method: '',
            cul_comments: '',
            showHistory: false,
            fromTechnician: false,
            enableFinishRpt: false,
            disableBtn: false,
        }
        this.uploadImage = React.createRef();
    }

    componentDidMount() {
        this.getBacteriaList()
        this.getAntiBacteriaList()
        this.getSignatureDetails()
        this.getDropDownDetails()
        let { location } = this.props?.history;
        let { resultList, fromTechnician } = location?.state;
        if (location?.state?.resultList) {
            this.setState({
                patient_status: resultList,
                fromTechnician: fromTechnician
            }, () => {
                this.getPatientResultStatus()
            })
        }

    }

    getSignatureDetails = () => {
        RestAPIService.getAll(Serviceurls.LAB_E_SIGNATURE_CONFIG).
            then((response) => {
                this.setState({ signatureList: response.data.data })
            }).catch((error) =>
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.message
                }))
    }

    getDropDownDetails = () => {
        RestAPIService.getAll(Serviceurls.LAB_RESULT_SIGNATURE).
            then((response) => {
                this.setState({
                    disableLeft: response.data.config?.esign_left,
                    disableCentre: response.data.config?.esign_center,
                    disableRight: response.data.config?.esign_right
                })
            }).catch((error) =>
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.message
                }))
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            isSuccessMsg: false,
            isSuccessMsgText: ''
        })
    }

    methodThreehandleOpen = (index) => {
        this.setState({
            methodThreeOpen: true,
            selectedSubIndex: index
        })
    }
    methodThreehandleClose = () => {
        this.setState({
            methodThreeOpen: false
        })
    }

    methodFourhandleOpen = (index) => {
        this.setState({
            methodFourOpen: true,
            selectedSubIndex: index
        })
    }
    methodFourHandleClose = (result_list) => {
        // let {patientResultStatus} = this.state
        // if(result_list.id === this.state.selectedRowId){
        //     if (result_list?.result?.value) {
        //         result_list.result.value = []
        //     } else {
        //         result_list.result = { value: [] }
        //     }
        // }
        this.setState({
            methodFourOpen: false,
            bacteria: null,
            antibacteria: null,
            Interpretation: "",
            autoZone: "",
            zoneResult: "",
            breakPoint: "",
            breakPointResult: "",
            methodThreeBacteriaResult: "",
            methodThreeResult: [],
            deleteData: false,
            microscopy: "",
            colonyCount: "",
            EditCulture: false,
            EditedIndex: "",
            EditedSubIndex: "",
            cul_method: '',
            cul_comments: '',
            // patientResultStatus
        })
    }

    async sourceOpen(list, subIndex) {
        let tempArr = [];
        let image = list.result_image
        if (image !== null) {
            for (let j = 0; j < image?.length; j++) {
                let doc_key = image[j];
                let id = list.id;
                let getData = await this.getS3Key(doc_key, id)
                tempArr.push(getData)
            }
        }
        this.setState({
            sourceOpen: true,
            selectedImageIndex: subIndex,
            selectedUploadData: list,
            uploadedImages: tempArr,
            selectedImage: null
        })
    }
    sourceClose = () => {
        this.setState({ sourceOpen: false })
    }

    onChangeHandler = (event, key) => {

        this.setState({
            [key]: event.target.value
        }, () => {
            if (key == "Interpretation" && this.state.bacteria != null && this.state.antibacteria != null) {
                var bacteriaID = ""; this.state.bacteriaList.forEach((element) => { if (element.name == this.state.bacteria?.name) { bacteriaID = element.id } })
                var antibioticID = ""; this.state.antiBacteriaList.forEach((element) => { if (element.name == this.state.antibacteria?.name) { antibioticID = element.id } })
                this.setState({
                    BacteriaId: bacteriaID,
                    AntibioticId: antibioticID
                }, () => { this.getInterpretationdata() })
            }
        })
    }

    onChange = (imageList, addUpdateIndex) => {
        this.setState({
            uploadedImages: [...this.state.uploadedImages, ...imageList.target.files]
        })
    };

    handlePageChange = (event, newPage) => {
        this.setState({
            page: newPage
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: (+event.target.value),
            page: 0

        })
    }

    getInterpretationdata = () => {
        try {
            RestAPIService.getAll(Serviceurls.LAB_CULTURE_TEST_CONFIG + "?bacteria_id=" + this.state.BacteriaId + "&antibiotic_id=" + this.state.AntibioticId + "&interpretation=" + this.state.Interpretation + "&id=" + this.state.selectedRowId).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            autoZone: response.data.data.zone,
                            breakPoint: response.data.data.mic
                        })
                    }
                }).catch((e) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: e.message
                    })
                })
        } catch (e) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: e.message
            })
        }
    }

    DeleteItem = (result_list, selectedItem) => {
        let updatedData = result_list.result.value.filter(item => item.bacteria !== selectedItem.bacteria);
        result_list.result.value = updatedData
        this.setState({
            methodThreeResult: updatedData,
            methodThreeBacteriaResult: "",
        });
    };

    submitBacteria = (dataSet, result_list) => {
        const { t } = this.props
        let bacteriaResult = dataSet
        let bacteriaData = {
            "bacteria": this.state.bacteria?.name,
            "colony_count": this.state.colonyCount,
            "microscopy": this.state.microscopy,
            "method_type": this.state.cul_method,
            "comments": this.state.cul_comments,
            "antibacteria": [{
                "antibiotic": this.state.antibacteria?.name,
                "mic_break_point_std": this.state.breakPoint,
                "mic_break_point": this.state.breakPointResult,
                "zone_size_std": this.state.autoZone,
                "zone_size": this.state.zoneResult,
                "interpretation": this.state.Interpretation,
                "methodThreeBacteriaResult": this.state.methodThreeBacteriaResult,
            }]
        }

        if (this.state.EditCulture === false) {
            bacteriaData["id"] = result_list?.result?.value?.length ? result_list?.result?.value?.length + 1 : 1;
        } else {
            bacteriaData["id"] = this.state.selectedId
        }

        if (!bacteriaData.bacteria && !bacteriaData.antibacteria[0].antibiotic && !bacteriaData.antibacteria[0].methodThreeBacteriaResult && !bacteriaData.antibacteria[0].interpretation) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields")
            })
        }
        else if (!bacteriaData.bacteria) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("Select Bacteria")
            })
        }
        // } else if (!bacteriaData.antibacteria[0].antibiotic) {
        //     this.setState({
        //         isErrorMsg: true,
        //         isErrorMsgText: t("Select AntiBiotic")
        //     })
        // } else if (!bacteriaData.antibacteria[0].interpretation) {
        //     this.setState({
        //         isErrorMsg: true,
        //         isErrorMsgText: t("Select Interpretation")
        //     })
        // } else if (!bacteriaData.antibacteria[0].methodThreeBacteriaResult) {
        //     this.setState({
        //         isErrorMsg: true,
        //         isErrorMsgText: t("Enter Efficiency Ratio")
        //     })
        // } 
        else {
            let duplicate = this.checkDuplicate()
            if (duplicate && !this.state.EditCulture) {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t("bacteriaExist")
                })
            } else {
                // var existingEntry = bacteriaResult.find(entry => entry?.bacteria?.toLowerCase() === bacteriaData?.bacteria?.toLowerCase());
                var existingEntry = this.state.EditCulture ? bacteriaResult.find(entry => entry?.id === bacteriaData?.id) : bacteriaResult.find(entry => entry?.bacteria?.toLowerCase() === bacteriaData?.bacteria?.toLowerCase());

                if (existingEntry) {
                    if (this.state.EditCulture) {
                        existingEntry.bacteria = this.state.bacteria?.name
                        existingEntry.microscopy = this.state.microscopy
                        existingEntry.colony_count = this.state.colonyCount
                        existingEntry.method_type = this.state.cul_method
                        existingEntry.comments = this.state.cul_comments
                        existingEntry.antibacteria[this.state.EditedSubIndex] = {
                            "antibiotic": this.state.antibacteria?.name,
                            "mic_break_point_std": this.state.breakPoint,
                            "zone_size_std": this.state.autoZone,
                            "mic_break_point": this.state.breakPointResult,
                            "zone_size": this.state.zoneResult,
                            "interpretation": this.state.Interpretation,
                            "methodThreeBacteriaResult": this.state.methodThreeBacteriaResult,
                        };

                    } else {
                        existingEntry.bacteria = this.state.bacteria?.name
                        existingEntry.microscopy = this.state.microscopy
                        existingEntry.colony_count = this.state.colonyCount
                        existingEntry.method_type = this.state.cul_method
                        existingEntry.comments = this.state.cul_comments
                        existingEntry.antibacteria.push({
                            "antibiotic": this.state.antibacteria?.name,
                            "mic_break_point_std": this.state.breakPoint,
                            "zone_size_std": this.state.autoZone,
                            "mic_break_point": this.state.breakPointResult,
                            "zone_size": this.state.zoneResult,
                            "interpretation": this.state.Interpretation,
                            "methodThreeBacteriaResult": this.state.methodThreeBacteriaResult,
                        });
                    }
                } else {
                    bacteriaResult.push(bacteriaData)
                }
                if (result_list.id === this.state.selectedRowId) {
                    if (result_list?.result?.value) {
                        result_list.result.value = bacteriaResult
                    } else {
                        result_list.result = { value: bacteriaResult }
                    }
                }
                this.setState({
                    methodThreeResult: bacteriaResult,
                    methodThreeBacteriaResult: "",
                    antibacteria: null,
                    Interpretation: "",
                    autoZone: "",
                    zoneResult: "",
                    breakPoint: "",
                    breakPointResult: "",
                    EditCulture: false,
                    EditedIndex: "",
                    EditedSubIndex: ""
                })
            }
        }
    }

    checkDuplicate = () => {
        let { bacteriaList, antiBacteriaList } = this.state;
        let bacteriaHash = {};
        let antibacteriaHash = {};
        let duplicate = false;
        bacteriaList.forEach(element => {
            bacteriaHash[element.name] = element.name
        })
        antiBacteriaList.forEach(element => {
            antibacteriaHash[element.name] = element.name
        })
        let newArray = [];
        this.state.checkList?.forEach(element => {
            for (let i = 0; i < element.antibacteria.length; i++) {
                let desiredValue = element.antibacteria[i].antibiotic;
                newArray.push(desiredValue);
            }
            if (bacteriaHash[element.bacteria] === this.state.bacteria?.name && antibacteriaHash[newArray] === this.state.antibacteria?.name) {
                duplicate = true
            }
        })
        return duplicate;
    }

    checkMethodFourValues = (result_list) => {
        const { t } = this.props
        if (result_list.id === this.state.selectedMethodFourRowId) {
            let row = result_list?.result?.value
            let paragraph = []; let table = []; let tableRow = []
            if (row) {
                if (row.impressions.length > 0) {
                    row.paragraph.map((item) => (paragraph = item.data))
                    if (paragraph.length > 0) {
                        row.table.map((item) => (item.noRows.map((val) => (
                            tableRow.push(item.noRows), val.value ? table.push(val.value) : ""))))
                        if (table.length === tableRow.length) {
                            this.setState({ methodThreeOpen: false })
                        } else {
                            this.setState({
                                isErrorMsg: true,
                                isErrorMsgText: t("ManitoryFields")
                            })
                        }
                    }
                }
            } else {
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: t("ManitoryFields")
                })
            }
        }
    }

    getBacteriaList = () => {
        RestAPIService.getAll(Serviceurls.LAB_PATIENT_BACTERIA_GET).
            then((response) => {
                if (response.data.status === "success") {
                    this.setState({ bacteriaList: response.data.data })
                }
            }).catch((error) => this.responseSuccessErrorMessage(error.response.data.message, false))
    }

    getAntiBacteriaList = () => {
        RestAPIService.getAll(Serviceurls.LAB_PATIENT_ANTI_BACTERIA_GET).
            then((response) => {
                this.setState({ antiBacteriaList: response.data.data })
            }).catch((error) =>
                this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.message
                }))
    }

    async renderImage(imageData) {
        let tempArr = [];
        for (let i = 0; i < imageData.length; i++) {
            let image = imageData[i].result_image
            if (image !== null) {
                for (let j = 0; j < image.length; j++) {
                    let doc_key = image[j];
                    let id = imageData[i].id;
                    let getData = await this.getS3Key(doc_key, id)
                    tempArr.push(getData)
                }
            }
        }
        this.setState({
            uploadedImages: tempArr,
            selectedImage: null
        })
    }
    getS3Key(doc_key, id) {

        return new Promise(resolve => {
            var parms = {
                Bucket: Bucket_Name,
                Key: doc_key, Expires: 300
            };
            s3.getSignedUrl("getObject", parms, (err, url) => {
                if (err) {
                    resolve(err)
                } else {
                    var data = {
                        "data_url": url,
                        "id": id,
                        "type": doc_key?.split('.')?.pop() === "pdf" ? "pdf" : "image"
                    }
                    resolve(data)
                }
            })
        })
    }

    getPatientResultStatus = () => {
        try {
            if (!this.state.isImageSucc) {
                this.setState({ patientResultStatus: [], PrintTestList: [] })
            }
            let result_summary_id = this.state.patient_status?.result_summary_id ? this.state.patient_status?.result_summary_id : this.state.patient_status?.id;
            let setName = this.state.patient_status.set_name === null ? '' : encodeURIComponent(this.state.patient_status?.set_name)
            let testName = this.state.patient_status.test_name === null ? '' : encodeURIComponent(this.state.patient_status?.test_name)
            let querParams = this.state.fromTechnician ? "?result_summary_id=" + result_summary_id + "&test_name=" + testName + "&set_name=" + setName : `?result_summary_id= ${result_summary_id}`
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_GET + querParams)
                .then((response) => {
                    if (response.data.status === "success") {

                        let newData = response.data.data
                        let imageData = []
                        newData.map((item) => {
                            item.data.forEach(element => {
                                if (element.is_outsource) {
                                    imageData.push(element)
                                }
                            })
                        })
                        if (imageData.length > 0) {
                            this.renderImage(imageData)
                        } else {
                            this.setState({
                                uploadedImages: []
                            })
                        }
                        // if(!this.state.isImageSucc){
                        this.setState({
                            patientResultStatus: response.data.data,
                            LeftDrop: response.data?.esign?.esign_left && Object.keys(response.data?.esign?.esign_left).length > 0 ? response.data?.esign?.esign_left : null,
                            CentreDrop: response.data?.esign?.esign_center && Object.keys(response.data?.esign?.esign_center).length > 0 ? response.data?.esign?.esign_center : null,
                            RightDrop: response.data?.esign?.esign_right && Object.keys(response.data?.esign?.esign_right).length > 0 ? response.data?.esign?.esign_right : null,
                        }, () => {
                            let { patientResultStatus, showPrintBtn, PrintTestList } = this.state;
                            let is_outSource_test = false;
                            let print_list = patientResultStatus.map(item => (
                                item?.data?.length > 0 && item.data.filter(li => li?.result?.value != null || li?.result_image != null).map(test => {
                                    if (
                                        test?.result !== null &&
                                        typeof test?.result === "object" &&
                                        ("value" in test?.result) && test?.is_outsource === false
                                    ) {
                                        test["isChecked"] = true;
                                        return test; // Keep the test object in the mapped array
                                    } else if ((test?.result_image !== null && test?.result_image?.length !== 0)
                                        && test?.is_outsource) {
                                        is_outSource_test = true;
                                    }
                                })
                            ))
                            showPrintBtn = print_list?.some(item => item[0] !== undefined && item?.length > 0)
                            const initialCheckboxState = print_list?.map((group) =>
                                group?.map((item) => ({
                                    isChecked: item?.isChecked,
                                    id: item?.id,
                                }))
                            );
                            this.setState({
                                showPrintBtn,
                                enableFinishRpt: is_outSource_test,
                                PrintTestList: print_list,
                                checkboxState: initialCheckboxState
                            },)
                        })
                        if (this.state.isImageSucc) {
                            let result = this.state.patientResultStatus
                            result.map((item) => (
                                item.data.forEach((element, index) => {
                                    if (element.is_outsource) {
                                        imageData.forEach((imgElement, imgIndex) => {
                                            if (element.id === imgElement.id) {
                                                element.result_image = imageData[imgIndex].result_image
                                            }
                                        })
                                    }
                                })
                            ))
                            this.setState({
                                patientResultStatus: result,
                                LeftDrop: response.data?.esign?.esign_left ? response.data?.esign?.esign_left : null,
                                CentreDrop: response.data?.esign?.esign_center ? response.data?.esign?.esign_center : null,
                                RightDrop: response.data?.esign?.esign_right ? response.data?.esign?.esign_right : null,
                            })
                        }
                    } else if (response.data.status === "fail") {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: response.data.message
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.message
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields")
            })
        }
    }



    postPatientResultStatus = () => {
        try {
            this.setState({ disableBtn: true })
            let resultValue = [];
            this.state.patientResultStatus?.map((item) => (
                item.data.map((result) => (
                    result.is_outsource ? "" :
                        result.method === "Positive and Negative Results" || result.method === "Present and Absent Result" ?
                            resultValue.push({ "result_item_id": result.id, "result": { "value": result.result?.value ? result.result?.value : null, "comment": result?.result?.comment ? result?.result?.comment : "" } })
                            :
                            result.method === "Radiology" ?
                                resultValue.push({ "result_item_id": result.id, "result": { "value": result.result?.value ? result.result?.value : result.result } })
                                :
                                resultValue.push({ "result_item_id": result.id, "result": { "value": result.result?.value?.length > 0 ? result.result?.value : null, "comment": result?.result?.comment ? result?.result?.comment : "" } })
                ))
            ))
            let data = {
                "result_summary_id": this.state.patient_status?.result_summary_id ? this.state.patient_status?.result_summary_id : this.state.patient_status?.id,
                "result_line_items": resultValue,
                "esign_center": this.state.CentreDrop?.id ? this.state.CentreDrop?.id : null,
                "esign_left": this.state.LeftDrop?.id ? this.state.LeftDrop?.id : null,
                "esign_right": this.state.RightDrop?.id ? this.state.RightDrop?.id : null,
            }
            RestAPIService.create(data, Serviceurls.LAB_PATIENT_RESULT_POST).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message
                        })
                        this.getPatientResultStatus()
                        this.setState({ SaveChangesClick: true, disableBtn: false })
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: response.data.message,
                            disableBtn: false,
                        })
                    }
                }).catch(error => this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message,
                    disableBtn: false,
                }))
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields"),
                disableBtn: false,
            })
        }
    }

    putPatientResultStatus = () => {
        try {
            let data = { "result_summary_id": this.state.patient_status?.result_summary_id ? this.state.patient_status?.result_summary_id : this.state.patient_status?.id }
            this.setState({ disableBtn: true })
            RestAPIService.updateWithOutId(data, Serviceurls.LAB_PATIENT_RESULT_PUT).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message
                        }, () => {
                            setTimeout(() => {
                                this.setState({ isSuccessMsg: false, disableBtn: false })
                                // var { history } = this.props
                                // history.push({ pathname: "/MainHome" })
                                let { history } = this.props
                                history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                            }, 500);
                        })
                        this.setState({
                            SaveChangesClick: false
                        })
                    } else {
                        this.setState({
                            isErrorMsg: true,
                            isErrorMsgText: response.data.message,
                            disableBtn: false,
                        })
                    }
                }).catch(error => this.setState({
                    isErrorMsg: true,
                    isErrorMsgText: error.response.data.message,
                    disableBtn: false,
                }))
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields"),
                disableBtn: false,
            })
        }
    }

    dataURItoBlob = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    uploadOutsourceImage = (list) => {
        try {
            let result_item_id;
            this.state.patientResultStatus?.map((item) => (
                item.data.map((result) => (
                    result.is_outsource ? (result_item_id = result.id) : null
                ))
            ))
            const dataForm = new FormData();
            var { uploadedImages } = this.state;
            let i = 0
            for (i; i < uploadedImages.length; i++) {
                if (!uploadedImages[i].id) {
                    // dataForm.append("result_images", this.dataURItoBlob(uploadedImages[i].data_url))
                    dataForm.append("result_images", uploadedImages[i])
                }

            }
            dataForm.append("result_item_id", list.id)

            RestAPIService.patch(dataForm, Serviceurls.LAB_PATIENT_OUTSOURCE_PATCH).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.mesage,
                            isImageSucc: true
                        })
                        if (this.state.isImageSucc) {
                            this.getPatientResultStatus()
                        }
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ImageError")
            })
        }
    }

    deleteUploadImages = (index, list) => {
        try {
            this.state.selectedDeletedIndex.push(index)
            let result_item_id;
            this.state.patientResultStatus?.map((item) => (
                item.data.map((result) => (
                    result.is_outsource ? (result_item_id = result.id, this.state.deleteDataList = result.result_image) : ""
                ))
            ))
            let daletedData = list.result_image.splice(index, 1)
            let data = {
                "result_item_id": list?.id,
                "result_images": list?.result_image?.length > 0 ? list.result_image : null
            }
            RestAPIService.delete(Serviceurls.LAB_PATIENT_RESULT_DELETE, data).
                then((response) => {
                    if (response.data.status === "success") {
                        this.onImageRemove(index);
                        this.setState({
                            isSuccessMsg: true,
                            isSuccessMsgText: response.data.message,
                            selectedImage: null
                        })
                    }
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.response.data.message,
                        selectedImage: null
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ImageError")
            })
        }
    }

    getResultPrint = () => {
        let { checkboxState } = this.state;
        let testIds = []
        this.setState({ isPrintClicked: true })
        checkboxState
            .flat()
            .filter((item) => item?.isChecked)
            .map((item) => testIds.push(item?.id));
        try {
            let result_summary_id = this.state.patient_status?.result_summary_id ? this.state.patient_status?.result_summary_id : this.state.patient_status?.id
            RestAPIService.getAll(Serviceurls.LAB_PATIENT_RESULT_PRINT + `?result_summary_id=${result_summary_id}&result_item_id=${testIds}`).
                then((response) => {
                    this.handleCancel()
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({ isPrintClicked: false })
                }).catch((error) => {
                    this.setState({
                        isErrorMsg: true,
                        isErrorMsgText: error.message,
                        isPrintClicked: false,
                    })
                })
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("PrintError"),
                isPrintClicked: false,
            })
        }
    }

    methodFourButtonPopUp = (result_list) => {
        try {
            const { t } = this.props
            const { patientResultStatus } = this.state
            let methodFour = result_list?.result?.value ? result_list.result.value : result_list.result
            return (
                <>
                    <Button variant='contained'
                        onClick={() => {
                            this.setState({
                                selectedMethodFourRowId: result_list.id,
                                selected_res: JSON.stringify(result_list)
                            })
                            this.methodThreehandleOpen()
                        }}
                        sx={{ textTransform: "capitalize" }}
                    >{methodFour !== "" ? t("updateResult") : t("EnterResult")}</Button>
                    {result_list.id === this.state.selectedMethodFourRowId ?
                        <Modal
                            open={this.state.methodThreeOpen}
                            onClose={this.methodThreehandleClose}
                        >
                            <Box className='emedhub_popup'>
                                <div className=''>
                                    <div className='emedhub_popup_title'>
                                        <label className='emedhub_popup_title_left'>{t("Test Report")}</label>
                                        <div className='emedhub_card_close_button'>
                                            <img className='emedhub_popup_title_right'
                                                src={ImagePaths.LabCloseButton.default}
                                                onClick={this.methodThreehandleClose}
                                                alt="Close"
                                            />
                                        </div>
                                    </div>
                                    <div className='emedhub_popup_title_2'>
                                        <Typography className='emedhub_popup_title_Header'>{t("Radiology")}</Typography>
                                        {/* <Autocomplete
                                className='emedhub_popup_title_dropdown'
                                size='small'
                                options={list.map((option) => option.sourceName)}
                                renderInput={(params) => <TextField {...params} label={t("Select Template")} />}
                            /> */}
                                    </div>
                                </div>
                                <div className='emedhub_card'>

                                    <div className='emedhub_popup_Impression_card'>
                                        <Typography className='emedhub_card_title'><b>{t("Impression")}</b></Typography>

                                        {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ?
                                            methodFour?.impressions.map((item, impIndex) => (
                                                <div className='emedhub_card_input'>
                                                    <TextField
                                                        label={t("ImpressionLine") + ` ${impIndex + 1}`}
                                                        sx={{ marginBottom: "1vw", marginTop: "0.5vw" }}
                                                        multiline
                                                        value={methodFour["impressions"][impIndex]}
                                                        disabled
                                                    />
                                                </div>
                                            )) : methodFour?.impressions.map((item, impIndex) => (
                                                <div className='emedhub_card_input'>
                                                    <TextField
                                                        label={t("ImpressionLine") + ` ${impIndex + 1}`}
                                                        sx={{ marginBottom: "1vw", marginTop: "0.5vw" }}
                                                        multiline
                                                        value={methodFour["impressions"][impIndex]}
                                                        onChange={(e) => {
                                                            let Data = e.target.value
                                                            methodFour["impressions"][impIndex] = Data
                                                            if (result_list?.result?.value) {
                                                                result_list.result.value = methodFour
                                                            } else {
                                                                result_list.result = { value: methodFour }
                                                            }
                                                            this.setState({ patientResultStatus })
                                                        }}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className='emedhub_popup_Paragraph_card'>
                                        <Typography className='emedhub_card_title'>
                                            <b>{t("Paragraph")}</b>
                                        </Typography>
                                        {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ?
                                            methodFour?.paragraph.map((para, paraMainIndex) => (
                                                <div className='emedhub_card_input'>
                                                    <label><b>{para.title}</b></label>
                                                    {para.data.map((paraLine, paraIndex) => (
                                                        <TextField
                                                            sx={{ marginBottom: "0.5vw", marginTop: "1vw" }}
                                                            label={t("Paragraph") + ` ${paraIndex + 1}`}
                                                            multiline
                                                            value={methodFour["paragraph"][paraMainIndex]["data"][paraIndex]}
                                                            disabled
                                                        />
                                                    ))}
                                                </div>
                                            )) :
                                            methodFour?.paragraph.map((para, paraMainIndex) => (
                                                <div className='emedhub_card_input'>
                                                    <label><b>{para.title}</b></label>
                                                    {para.data.map((paraLine, paraIndex) => (
                                                        <TextField
                                                            sx={{ marginBottom: "0.5vw", marginTop: "1vw" }}
                                                            label={t("Paragraph") + ` ${paraIndex + 1}`}
                                                            multiline
                                                            value={methodFour["paragraph"][paraMainIndex]["data"][paraIndex]}
                                                            onChange={(e) => {
                                                                let Data = e.target.value
                                                                methodFour["paragraph"][paraMainIndex]["data"][paraIndex] = Data
                                                                if (result_list?.result?.value) {
                                                                    result_list.result.value = methodFour
                                                                    this.state.method.push(methodFour)
                                                                } else {
                                                                    result_list.result = { value: methodFour }
                                                                    this.state.method.push({ value: methodFour })
                                                                }
                                                                this.setState({ patientResultStatus })
                                                            }}
                                                        />
                                                    ))}
                                                </div>
                                            ))
                                        }

                                    </div>
                                    <div className='emedhub_popup_Table_card'>
                                        <Typography className='emedhub_card_title'><b>{t("Table")}</b></Typography>
                                        {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ?
                                            methodFour?.table.map((tab, tabIndex) => (
                                                <div className=''>
                                                    <div style={{ margin: "0.5vw" }}>
                                                        <label><b>{tab.title}</b></label>
                                                    </div>
                                                    {tab.noRows.map((tabRow, rowIndex) => (
                                                        <div className='table_row_header'>
                                                            <div className='table_row'>
                                                                <label>{tabRow?.heading ? tabRow?.heading : tabRow}</label>
                                                            </div>
                                                            <div className='table_row_input'>
                                                                <TextField
                                                                    size='small'
                                                                    style={{ marginBottom: "0.5vw" }}
                                                                    value={methodFour["table"][tabIndex]["noRows"][rowIndex].value ? methodFour["table"][tabIndex]["noRows"][rowIndex].value : ""}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )) : methodFour?.table.map((tab, tabIndex) => (
                                                <div className=''>
                                                    <div style={{ margin: "0.5vw" }}>
                                                        <label><b>{tab.title}</b></label>
                                                    </div>
                                                    {tab.noRows.map((tabRow, rowIndex) => (
                                                        <div className='table_row_header'>
                                                            <div className='table_row'>
                                                                <label>{tabRow?.heading ? tabRow?.heading : tabRow}</label>
                                                            </div>
                                                            <div className='table_row_input'>
                                                                <TextField
                                                                    size='small'
                                                                    style={{ marginBottom: "0.5vw" }}
                                                                    value={methodFour["table"][tabIndex]["noRows"][rowIndex].value ? methodFour["table"][tabIndex]["noRows"][rowIndex].value : ""}
                                                                    onChange={(e) => {
                                                                        let Data = e.target.value
                                                                        let obj = { id: (rowIndex + 1), value: Data, heading: tabRow.heading ? tabRow.heading : tabRow }
                                                                        methodFour["table"][tabIndex]["noRows"][rowIndex] = obj
                                                                        if (result_list?.result?.value) {
                                                                            result_list.result.value = methodFour
                                                                            this.state.method.push(methodFour)
                                                                        } else {
                                                                            result_list.result = { value: methodFour }
                                                                            this.state.method.push({ value: methodFour })
                                                                        }
                                                                        this.setState({ patientResultStatus })
                                                                    }}

                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ? ""
                                    : <div className='emedhub_popup_button '>
                                        <Button
                                            variant='outlined'
                                            sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                                            onClick={() => {
                                                if (result_list.id === this.state.selectedMethodFourRowId) {
                                                    let res = JSON.parse(this.state.selected_res)
                                                    result_list.result = res.result
                                                    this.setState({ patientResultStatus })
                                                }
                                            }}
                                        >{t("Reset")}</Button>
                                        <Button
                                            variant='contained'
                                            sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                                            onClick={() => this.checkMethodFourValues(result_list)}
                                        >{t("SaveChanges")}</Button>
                                    </div>}
                            </Box>
                        </Modal> : null
                    }
                </>
            )
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields")
            })
        }
    }

    deleteBacteria = (index, result_list) => {
        result_list.result.value.splice(index, 1);
        let dataArray = result_list.result.value
        this.setState({
            methodThreeResult: dataArray,
            methodThreeBacteriaResult: "",
        });
    }

    deleteData = (parentIndex, dataIndex, result_list) => {
        result_list.result.value[parentIndex].antibacteria.splice(dataIndex, 1);
        let dataArray = result_list.result.value
        this.setState({
            methodThreeResult: dataArray,
            methodThreeBacteriaResult: "",
        });
    }

    EditData = (Data, SubData, Index, subIndex) => {
        let bacteriaData = {}
        let antibacteriaData = {}
        if (Data?.bacteria) {
            bacteriaData = { id: "", name: Data.bacteria }
        } else { bacteriaData = null }
        if (SubData?.antibiotic) {
            antibacteriaData = { id: "", name: SubData.antibiotic }
        } else { antibacteriaData = null }
        this.setState({
            EditCulture: true,
            EditedIndex: Index,
            EditedSubIndex: subIndex,
            bacteria: bacteriaData,
            selectedId: Data?.id,
            colonyCount: Data.colony_count,
            microscopy: Data.microscopy,
            antibacteria: antibacteriaData,
            breakPointResult: SubData.mic_break_point,
            zoneResult: SubData.zone_size,
            Interpretation: SubData.interpretation,
            methodThreeBacteriaResult: SubData.methodThreeBacteriaResult,
            breakPoint: SubData.mic_break_point_std,
            autoZone: SubData.zone_size_std,
            cul_method: Data.method_type,
            cul_comments: Data.comments,
        })
    }

    methodThreeButtonPopUp = (result_list) => {
        try {
            const { t } = this.props
            let states = this.state
            const Interpretation = [
                {
                    result_name: "Sensitive", value: 0
                }, {
                    result_name: "Intermediate", value: 1
                }, {
                    result_name: "Resistant", value: 2
                }
            ]

            let { patientResultStatus, methodThreeResult, selectedSubIndex } = this.state;
            var dataSet = [];
            if (result_list?.result != null && result_list.result?.value?.length > 0) {
                dataSet = result_list?.result?.value;
                this.state.checkList = result_list.result?.value;
            } else {
                dataSet = methodThreeResult;
                this.state.checkList = methodThreeResult;
            }
            return (
                <>
                    <Button
                        variant='contained'
                        onClick={() => {
                            this.setState({
                                selectedRowId: result_list?.id,
                                selected_res_three: JSON.stringify(result_list)
                            })
                            this.methodFourhandleOpen()
                        }}
                        sx={{ textTransform: "capitalize" }}
                    >{result_list?.result?.value?.length > 0 ? t("updateResult") : t("EnterResult")}</Button>
                    {result_list.id === this.state.selectedRowId ?
                        <Modal
                            open={this.state.methodFourOpen}
                            onClose={() => this.methodFourHandleClose()}>
                            <Box className='emedhub_popup' sx={{ width: "80vw" }}>
                                <div className=''>
                                    <div className='emedhub_popup_title'>
                                        <label className='emedhub_popup_title_left'>{t("TestReport")}</label>
                                        <div className='emedhub_card_close_button'>
                                            <img
                                                className='emedhub_popup_title_right'
                                                src={ImagePaths.LabCloseButton.default}
                                                onClick={() => {
                                                    if (result_list.id === this.state.selectedRowId) {
                                                        let res = JSON.parse(this.state.selected_res_three)
                                                        result_list.result = res.result
                                                        this.setState({ patientResultStatus })
                                                    }
                                                    this.setState({
                                                        methodThreeResult: [],
                                                        patientResultStatus
                                                    }, () => {
                                                        this.clearCulture()
                                                        this.methodFourHandleClose()
                                                    })
                                                }}
                                                alt='close'
                                            />
                                        </div>
                                    </div>
                                    <div className='emedhub_popup_title_2' style={{ marginTop: "0.5vw" }}>
                                        <Typography className='emedhub_popup_title_Header'>{t("CulptureTest")}</Typography>
                                    </div>
                                </div>
                                <div style={{ width: "77.5vw", height: "33.5vw", marginTop: "1vw" }}>
                                    {(this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") ?
                                        <Typography sx={{ margin: "auto" }}></Typography> :
                                        <div className='emedhub_method_four_card_item'>
                                            <div className='emedhub_culture_screen' >
                                                <div>
                                                    <Autocomplete
                                                        size='small'
                                                        options={this.state.bacteriaList}
                                                        getOptionLabel={(option) => (option?.name)}
                                                        // disabled={this.state.EditCulture}
                                                        value={this.state?.bacteria}
                                                        onChange={(event, newValue) => {
                                                            states.bacteria = newValue
                                                            this.setState({ states })
                                                            // this.setState({ states }, () => {
                                                            //     this.setState({ Interpretation: "", autoZone: "", breakPoint: "", microscopy: "", colonyCount: "", cul_comments: '', cul_method: '', })
                                                            // })
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} style={{ width: "20vw" }}
                                                                label={'Select Isolated Organism*'}
                                                            // onChange={(e) => {
                                                            //     this.setState({ Interpretation: "", autoZone: "", breakPoint: "", microscopy: "", colonyCount: "" },
                                                            //         () => {
                                                            //             let data = { id: "", name: e.target.value }
                                                            //             this.setState({ bacteria: data })
                                                            //         })
                                                            // }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <TextField
                                                        size='small'
                                                        label={t("Microscopy")}
                                                        value={this.state.microscopy}
                                                        onChange={(e) => this.onChangeHandler(e, "microscopy")}
                                                    />
                                                </div>
                                                <div>
                                                    <TextField
                                                        size='small'
                                                        label={t("Method Type")}
                                                        inputProps={{ maxLength: 50 }}
                                                        value={this.state.cul_method}
                                                        onChange={(e) => this.onChangeHandler(e, "cul_method")}
                                                    />
                                                </div>
                                                <div>
                                                    <TextField
                                                        size='small'
                                                        label={t("Colony Count")}
                                                        value={this.state.colonyCount}
                                                        onChange={(e) => this.onChangeHandler(e, "colonyCount")}
                                                    />
                                                </div>
                                                <div>
                                                    <TextField
                                                        size='small'
                                                        label={t("Comments")}
                                                        inputProps={{ maxLength: 100 }}
                                                        value={this.state.cul_comments}
                                                        onChange={(e) => this.onChangeHandler(e, "cul_comments")}
                                                    />
                                                </div>
                                            </div>
                                            <div className='emedhub_culture_screen' style={{ marginTop: "1vw" }}>
                                                <div>
                                                    <Autocomplete
                                                        size='small'
                                                        options={this.state?.antiBacteriaList}
                                                        getOptionLabel={(option) => (option?.name)}
                                                        value={this.state?.antibacteria}
                                                        onChange={(event, newValue) => {
                                                            states.antibacteria = newValue
                                                            this.setState({ states, Interpretation: "" })
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} style={{ width: "16vw" }}
                                                                label={'Select Antibiotic'}
                                                            // onChange={(e) => {
                                                            //     let data = { id: "", name: e.target.value }
                                                            //     this.setState({ antibacteria: data })
                                                            // }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div>
                                                    <FormControl size='small' sx={{ width: "11vw" }}>
                                                        <InputLabel>{t("Interpretation")}</InputLabel>
                                                        <Select
                                                            label={t("Interpretation")}
                                                            value={this.state.Interpretation}
                                                            onChange={(e) => this.onChangeHandler(e, "Interpretation")}
                                                            MenuProps={{
                                                                style: { maxHeight: 350 },
                                                            }}
                                                        >
                                                            {Interpretation.map((item) => {
                                                                return <MenuItem value={item?.result_name} key={item?.value}>{item?.result_name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <TextField
                                                    size='small'
                                                    label={t("Zone Size (MM)")}
                                                    value={this.state.zoneResult}
                                                    onChange={(e) => {
                                                        let value = e.target.value
                                                        if (value.length <= 15) {
                                                            this.setState({ zoneResult: e.target.value })
                                                        }
                                                    }}
                                                />
                                                <TextField
                                                    size='small'
                                                    label={t("MIC Break Point")}
                                                    value={this.state.breakPointResult}
                                                    onChange={(e) => {
                                                        let value = e.target.value
                                                        if (value.length <= 15) {
                                                            this.setState({ breakPointResult: e.target.value })
                                                        }
                                                    }}
                                                />
                                                <div>
                                                    <TextField
                                                        size='small'
                                                        label={t("Efficacy Ratio")}
                                                        value={this.state.methodThreeBacteriaResult}
                                                        onChange={(e) => this.onChangeHandler(e, "methodThreeBacteriaResult")}
                                                    />
                                                </div>
                                                <Button
                                                    variant='contained'
                                                    style={{ width: "6.5vw", height: "2.5vw" }}
                                                    onClick={() => {
                                                        this.submitBacteria(dataSet, result_list)
                                                    }}
                                                >{t("Add")}</Button>
                                            </div>
                                        </div>
                                    }
                                    <div style={{ height: "55vh", overflow: "scroll", marginTop: "1vw" }}>
                                        {result_list?.result?.value?.length > 0 ? result_list?.result?.value?.map((items, indexs) => (
                                            <div className="emedhub_card_method_4_table">
                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "70vw", marginTop: "1.3vw", marginBottom: "1vw" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <div style={{ marginRight: "0.5vw" }}><CommonDeleteButton disable={(this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") ? true : false} size="1.5vw" onClick={() => { this.deleteBacteria(indexs, result_list) }} /></div>
                                                        <Box component={'div'} sx={{ display: "flex" }}>
                                                            <Typography>{`ORGANISM ISOLATED 0${indexs + 1} : `}</Typography>
                                                            <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                                {items?.bacteria ? items?.bacteria?.length > 20 ?
                                                                    <Tooltip placement='top' title={items?.bacteria}>
                                                                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                                            {items?.bacteria.slice(0, 20) + '...'}
                                                                        </Typography>
                                                                    </Tooltip> :
                                                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.bacteria}</Typography> : '-'}
                                                            </Typography>
                                                        </Box>
                                                    </div>
                                                    <Box component={'div'} sx={{ display: "flex" }}>
                                                        <Typography>{`MICROSCOPY : `}</Typography>
                                                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                            {items?.microscopy ? items?.microscopy?.length > 20 ?
                                                                <Tooltip placement='top' title={items?.microscopy}>
                                                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                                        {items?.microscopy.slice(0, 20) + '...'}
                                                                    </Typography>
                                                                </Tooltip> :
                                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.microscopy}</Typography> : '-'}
                                                        </Typography>
                                                    </Box>
                                                    <Box component={'div'} sx={{ display: "flex" }}>
                                                        <Typography>{`COLONY COUNT : `}</Typography>
                                                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                            {items?.colony_count ? items?.colony_count?.length > 20 ?
                                                                <Tooltip placement='top' title={items?.colony_count}>
                                                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                                        {items?.colony_count.slice(0, 20) + '...'}
                                                                    </Typography>
                                                                </Tooltip> :
                                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.colony_count}</Typography> : '-'}
                                                        </Typography>
                                                    </Box>
                                                    <Box component={'div'} sx={{ display: "flex" }}>
                                                        <Typography>{`METHOD : `}</Typography>
                                                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                            {items?.method_type ? items?.method_type?.length > 20 ?
                                                                <Tooltip placement='top' title={items?.method_type}>
                                                                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                                                                        {items?.method_type.slice(0, 20) + '...'}
                                                                    </Typography>
                                                                </Tooltip> :
                                                                <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.method_type}</Typography> : '-'}
                                                        </Typography>
                                                    </Box>
                                                </div>
                                                <TableContainer style={{ maxHeight: "35vh" }}>
                                                    <Table style={{ border: "1px solid gray" }} stickyHeader>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("SNo")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Antibiotic")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("MIC Result/Range")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Zone Result/Range")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Interpretaion")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Efficacy Ratio")}</b></TableCell>
                                                                <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Action")}</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {items?.antibacteria?.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell>{index + 1}</TableCell>
                                                                    <TableCell>
                                                                        {item?.antibiotic ? item?.antibiotic?.length > 20 ?
                                                                            <Tooltip placement='top' title={item?.antibiotic}>
                                                                                <Typography>
                                                                                    {item?.antibiotic.slice(0, 20) + '...'}
                                                                                </Typography>
                                                                            </Tooltip> :
                                                                            <Typography>{item?.antibiotic}</Typography> : '-'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                                            <Typography>{item?.mic_break_point ? item?.mic_break_point?.length > 20 ?
                                                                                <Tooltip placement='top' title={item?.mic_break_point}>
                                                                                    <div>{item?.mic_break_point.slice(0, 20) + '...' + " "}</div>
                                                                                </Tooltip> : (item?.mic_break_point + " ") : '-'}</Typography>
                                                                            {item?.mic_break_point_std ?
                                                                                <Typography style={{ marginLeft: "0.25vw" }}>{item?.mic_break_point_std ? item?.mic_break_point_std?.length > 20 ?
                                                                                    <Tooltip placement='top' title={item?.mic_break_point_std}>
                                                                                        <div>{" / " + item?.mic_break_point_std.slice(0, 20) + '...'}</div>
                                                                                    </Tooltip> : (" / " + item?.mic_break_point_std) : '-'}</Typography> : null}
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                                                            <Typography>{item?.zone_size ? item?.zone_size?.length > 20 ?
                                                                                <Tooltip placement='top' title={item?.zone_size}>
                                                                                    <div>{item?.zone_size.slice(0, 20) + '...' + " "}</div>
                                                                                </Tooltip> : (item?.zone_size + " ") : '-'}</Typography>
                                                                            {item?.zone_size_std ?
                                                                                <Typography style={{ marginLeft: "0.25vw" }}>{item?.zone_size_std ? item?.zone_size_std?.length > 20 ?
                                                                                    <Tooltip placement='top' title={item?.zone_size_std}>
                                                                                        <div>{" / " + item?.zone_size_std.slice(0, 20) + '...'}</div>
                                                                                    </Tooltip> : (" / " + item?.zone_size_std) : '-'}</Typography> : null
                                                                            }
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item?.interpretation ? item?.interpretation?.length > 20 ?
                                                                            <Tooltip placement='top' title={item?.interpretation}>
                                                                                <Typography>
                                                                                    {item?.interpretation.slice(0, 20) + '...'}
                                                                                </Typography>
                                                                            </Tooltip> :
                                                                            <Typography>{item?.interpretation}</Typography> : '-'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        {item?.methodThreeBacteriaResult ? item?.methodThreeBacteriaResult?.length > 20 ?
                                                                            <Tooltip placement='top' title={item?.methodThreeBacteriaResult}>
                                                                                <Typography>
                                                                                    {item?.methodThreeBacteriaResult.slice(0, 20) + '...'}
                                                                                </Typography>
                                                                            </Tooltip> :
                                                                            <Typography>{item?.methodThreeBacteriaResult}</Typography> : '-'}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <CommonEditButton disable={(this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") ? true : false} size="1.5vw" onClick={() => { this.EditData(items, item, indexs, index) }} />
                                                                        <CommonDeleteButton disable={(this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") ? true : false} size="1.5vw" onClick={() => { this.deleteData(indexs, index, result_list) }} />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                        )) : <div style={{ marginTop: "1.5vw", width: "75vw", marginLeft: "1.25vw", height: "50vh" }}>
                                            <TableContainer style={{ border: "1px solid black" }}>
                                                <Table stickyHeader>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("SNo")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Antibiotic")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("MIC Result/Range")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Zone Result/Range")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Interpretaion")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Efficacy Ratio")}</b></TableCell>
                                                            <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{t("Action")}</b></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                </Table>
                                            </TableContainer>
                                            <div style={{ marginTop: "10vw", marginLeft: "32vw" }}>
                                                <div>No Records To Be Shown</div>
                                            </div>
                                        </div>}
                                    </div>
                                </div>


                                {(this.state.patient_status?.status === "Result Completed") || (this.state.patient_status?.status === "Result Delivered") ?
                                    "" :
                                    <div className='emedhub_popup_button method_four'>
                                        <Button
                                            variant='outlined'
                                            sx={{ marginRight: "1vw", textTransform: "capitalize" }}
                                            onClick={() => {
                                                if (result_list.id === this.state.selectedRowId) {
                                                    let res = JSON.parse(this.state.selected_res_three)
                                                    result_list.result = res.result
                                                    this.setState({ patientResultStatus })
                                                }
                                                this.setState({
                                                    methodThreeResult: [],
                                                    patientResultStatus
                                                }, () => { this.clearCulture() })
                                            }}
                                        >{t("Reset")}</Button>
                                        <Button variant='contained'
                                            onClick={() => {
                                                this.setState({
                                                    methodFourOpen: false,
                                                    methodThreeResult: []
                                                }, () => { this.clearCulture() })
                                            }}
                                            sx={{ textTransform: "capitalize" }}>{t("Continue")}</Button>
                                    </div>
                                }
                            </Box>
                        </Modal> : null}
                </>
            )
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: t("ManitoryFields")
            })
        }
    }

    clearCulture = () => {
        this.setState({
            bacteria: null,
            antibacteria: null,
            microscopy: "",
            colonyCount: "",
            EditCulture: false,
            EditedIndex: "",
            EditedSubIndex: "",
            Interpretation: "",
            autoZone: "",
            zoneResult: "",
            breakPoint: "",
            breakPointResult: "",
            methodThreeBacteriaResult: "",
            cul_method: '',
            cul_comments: '',
        })
    }

    onImageRemove = (index) => {
        let deletedArr = [...this.state.uploadedImages];
        deletedArr.splice(index, 1);
        this.setState({
            uploadedImages: [...deletedArr]
        })
    }

    renderSourcePopUp = (list, subIndex) => {
        const { t } = this.props
        return (
            <>
                <Button
                    variant='contained'
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                        this.setState({ selectedImageRowId: list.id })
                        this.sourceOpen(list, subIndex)
                    }}>{t("upload PDF")}</Button>
                <Modal
                    open={this.state.sourceOpen}
                    onClose={this.sourceClose}
                >

                    <Box className='emedhub_popup_outsource'>
                        <div className=''>
                            <div className='emedhub_popup_title'>
                                <label className='emedhub_popup_title_left'>{t("TestReport")}</label>
                                <div className='emedhub_card_close_button'>
                                    <img
                                        className='emedhub_popup_title_right'
                                        src={ImagePaths.LabCloseButton.default}
                                        onClick={this.sourceClose}
                                        alt="close"
                                    />
                                </div>
                            </div>
                            <div className='emedhub_popup_title_2'>
                                <Typography className='emedhub_popup_title_Header'>{t("SculptureTest")}</Typography>
                            </div>
                        </div>
                        <div className='emedhub_print_popup'  >
                            <input
                                type="file"
                                multiple
                                // accept="application/pdf, image/jpg, image/jpeg, image/png"
                                accept="application/pdf"
                                onChange={(e) => this.onChange(e)}
                                ref={this.uploadImage}
                                style={{ display: "none" }}
                            />
                            {/* <ImageUploading
                                multiple
                                value={this.state.uploadedImages}
                                onChange={this.onChange}
                                maxNumber={5}
                                dataURLKey="data_url"
                            >
                                {({
                                    imageList,
                                    onImageUpload,
                                    onImageRemove
                                }) => ( */}
                            <div>
                                <div className='image_top_header'>
                                    <label className='test_name_style'><b>{t("TestName")}:</b>
                                        <span>{" " + this.state.selectedUploadData?.test_name}</span></label>


                                    {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ? "" :
                                        <div className='emedhub_image_upload_button'>
                                            <button className='image_upload_button' onClick={() => this.uploadImage.current.click()}>{t("upload PDF")}</button></div>}

                                </div>
                                {/* <div>
                                            <div className='emedhub_image_size'>
                                                {this.state.uploadedImages?.length > 0 ? <img src={this.state.selectedImage} className='emedhub_image_resolution' alt='' /> : ""}
                                            </div>
                                        </div> */}
                                <div style={{ display: "flex", width: "100%", overflow: "auto", overflowY: "hidden" }}>
                                    {this.state.uploadedImages?.map((item, index) => (
                                        <div key={index} className='emedhub_multiple_image_size'>
                                            <img
                                                alt="image"
                                                src={item?.type === "image" ? item["data_url"] : (item?.type === "application/pdf" || item?.type === "pdf") ? ImagePaths.DocumentPreview.default : URL.createObjectURL(item)}
                                                className='emedhub_multiple_image_resolution'
                                                onClick={() => {
                                                    if (item?.type === "application/pdf" || item?.type === "pdf") {
                                                        window.open(item?.type === "pdf" ? item["data_url"] : URL.createObjectURL(item))
                                                    }
                                                    this.setState({
                                                        selectedImage: item?.type === "image" ?
                                                            item["data_url"] : item?.type === "pdf"
                                                                ? ImagePaths.DocumentPreview.default : URL.createObjectURL(item)
                                                    })
                                                }} />
                                            {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ? "" :
                                                <div>
                                                    <IconButton onClick={() => {
                                                        this.setState({
                                                            selectedImage: null
                                                        })
                                                        if (item?.type === "image" || item?.type === "pdf") { // delete api call
                                                            this.deleteUploadImages(index, this.state.selectedUploadData)
                                                        } else {
                                                            this.onImageRemove(index);
                                                        }
                                                    }}
                                                        size='small'
                                                        className='emedhub_icon_style'
                                                    ><Close fontSize='small' /></IconButton>
                                                </div>}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {/* )}
                            </ImageUploading> */}
                        </div>
                        {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ? "" :
                            <div className='emedhub_popup_button method_four'>
                                <Button variant='outlined'
                                    onClick={() => {
                                        let image = [];
                                        this.state.patientResultStatus?.map((item) => (
                                            item.data.forEach(element => {
                                                if (element.id === this.state.selectedImageRowId) {
                                                    image = element.result_image
                                                }
                                            })
                                        ))
                                        if (image?.length !== this.state.uploadedImages?.length) {
                                            let removeImg = this.state.uploadedImages.pop()
                                            this.setState({ uploadedImages: this.state.uploadedImages })
                                        }
                                    }}
                                    sx={{ marginRight: "1vw", textTransform: "capitalize" }}>{t("Reset")}</Button>
                                <Button
                                    variant='contained'
                                    sx={{ textTransform: "capitalize" }}
                                    onClick={() => {
                                        this.uploadOutsourceImage(this.state.selectedUploadData)
                                        this.setState({ sourceOpen: false })
                                    }}
                                >{t("SaveChanges")}</Button>
                            </div>}
                    </Box>
                </Modal>
            </>
        )
    }

    resultMethodTypeList = (method, mainIndex, subIndex, list, result_list) => {
        let { patientResultStatus } = this.state
        let values = (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) ?
            patientResultStatus[mainIndex]['data'][subIndex]['result'].value : ''
        let comment = (patientResultStatus[mainIndex]['data'][subIndex]['result']?.comment) ?
            patientResultStatus[mainIndex]['data'][subIndex]['result'].comment : ''
        return (
            method === "Range Based Result" || method === "Lesser and Greater Result" ?
                <div style={{ display: "flex", flexDirection: "row" }}>
                    {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ?
                        <>
                            <TextField
                                sx={{ width: "10vw", marginRight: "0.5vw" }}
                                size='small'
                                value={list?.value}
                                disabled
                            />
                            <TextField
                                sx={{ width: "15vw", marginRight: "0.5vw" }}
                                size='small'
                                label={"Comments"}
                                value={list?.comment}
                                disabled
                            />
                        </>
                        :
                        <><TextField
                            sx={{ width: "10vw", marginRight: "0.5vw" }}
                            size='small'
                            value={values}
                            onChange={(e) => {
                                let Data = e.target.value
                                if(Data !== ''){
                                    if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                        patientResultStatus[mainIndex]['data'][subIndex]['result']['value'] = Data
                                    } else {
                                        patientResultStatus[mainIndex]['data'][subIndex]['result'] = { value: Data }
                                    }
                                    this.setState({
                                        patientResultStatus
                                    })
                                }else{
                                    patientResultStatus[mainIndex]['data'][subIndex]['result'] = null
                                    this.setState({ patientResultStatus })
                                }
                            }}
                        />
                            <TextField
                                sx={{ width: "8vw", marginLeft: "0.5vw" }}
                                size='small'
                                value={comment}
                                placeholder={"Comments"}
                                inputProps={{
                                    maxLength: 300,
                                }}
                                onChange={(e) => {
                                    if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                        let Data = e.target.value
                                        if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.comment) {
                                            patientResultStatus[mainIndex]['data'][subIndex]['result']['comment'] = Data
                                        } else {
                                            patientResultStatus[mainIndex]['data'][subIndex]['result']["comment"] = Data
                                        }
                                        this.setState({
                                            patientResultStatus
                                        })
                                    } else {
                                        this.setState({
                                            isErrorMsg: true,
                                            isErrorMsgText: "Enter Result"
                                        })
                                    }
                                }}
                            />
                        </>

                    }
                </div> :
                method === "Positive and Negative Results" || method === "Present and Absent Result" ?
                    <div>
                        {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ?
                            <div className='emedhub_lab_Result'>
                                <TextField
                                    sx={{ width: "10vw", marginRight: "0.5vw" }}
                                    size='small'
                                    value={list?.value}
                                    disabled
                                />
                                <TextField
                                    sx={{ width: "15vw", marginRight: "0.5vw" }}
                                    size='small'
                                    label={"Comments"}
                                    value={list?.comment}
                                    disabled
                                /></div>
                            :
                            <div className='emedhub_lab_Result'>
                                <FormControl size='small' sx={{ width: "7.5vw" }}>
                                    <InputLabel>Select</InputLabel>
                                    <Select
                                        label='Select'
                                        value={values}
                                        onChange={(e) => {
                                            let Data = e.target.value
                                            if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                                patientResultStatus[mainIndex]['data'][subIndex]['result']['value'] = Data
                                            } else {
                                                patientResultStatus[mainIndex]['data'][subIndex]['result'] = { value: Data }
                                            }
                                            this.setState({
                                                patientResultStatus
                                            })
                                        }}
                                    >
                                        {method === "Present and Absent Result" ?
                                            PresAbsen.map((item) => {
                                                return <MenuItem value={item?.result_name} key={item?.value}>{item?.result_name}</MenuItem>
                                            }) :
                                            PosiNega.map((item) => {
                                                return <MenuItem value={item?.result_name} key={item?.value}>{item?.result_name}</MenuItem>
                                            })}
                                    </Select>
                                </FormControl>
                                {((values != "Positive" || values != "Negative") && method === "Positive and Negative Results") ?
                                    <Tooltip placement='top' title={values ? values : null}>
                                        <TextField
                                            sx={{ width: "7.5vw" }}
                                            size='small'
                                            value={values}
                                            disabled={values === "Positive" || values === "Negative" ? true : false}
                                            onChange={(e) => {
                                                let Data = e.target.value;
                                                if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                                    patientResultStatus[mainIndex]['data'][subIndex]['result']['value'] = Data
                                                } else {
                                                    patientResultStatus[mainIndex]['data'][subIndex]['result'] = { value: Data }
                                                }
                                                this.setState({
                                                    patientResultStatus
                                                })
                                            }}
                                        /></Tooltip> : null}
                                {((values != "Present" || values != "Absent") && method === "Present and Absent Result") ?
                                    <Tooltip placement='top' title={values ? values : null}>
                                        <TextField
                                            sx={{ width: "7.5vw" }}
                                            size='small'
                                            value={values}
                                            disabled={values === "Present" || values === "Absent" ? true : false}
                                            onChange={(e) => {
                                                let Data = e.target.value;
                                                if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                                    patientResultStatus[mainIndex]['data'][subIndex]['result']['value'] = Data
                                                } else {
                                                    patientResultStatus[mainIndex]['data'][subIndex]['result'] = { value: Data }
                                                }
                                                this.setState({
                                                    patientResultStatus
                                                })
                                            }}
                                        /></Tooltip> : null}
                                <TextField
                                    sx={{ width: "8vw", marginLeft: "0.5vw" }}
                                    size='small'
                                    value={comment}
                                    placeholder={"Comments"}
                                    inputProps={{
                                        maxLength: 300,
                                    }}
                                    onChange={(e) => {
                                        if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                            let Data = e.target.value
                                            if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.comment) {
                                                patientResultStatus[mainIndex]['data'][subIndex]['result']['comment'] = Data
                                            } else {
                                                patientResultStatus[mainIndex]['data'][subIndex]['result']["comment"] = Data
                                            }
                                            this.setState({
                                                patientResultStatus
                                            })
                                        } else {
                                            this.setState({
                                                isErrorMsg: true,
                                                isErrorMsgText: "Select Positive / Negative"
                                            })
                                        }
                                    }}
                                />
                            </div>
                        }
                    </div>
                    :
                    method === "Culture Test Result" ? this.methodThreeButtonPopUp(result_list) :
                        method === "Radiology" ? this.methodFourButtonPopUp(result_list) :
                            method === 'Free Text Based Result' ? <div style={{ display: "flex", flexDirection: "row" }}>
                                {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ?
                                    <Box>
                                        <TextField
                                            sx={{ width: "30vw", marginRight: "0.5vw" }}
                                            size='small'
                                            value={list?.value}
                                            disabled
                                        />
                                        <TextField
                                            sx={{ width: "15vw", marginTop: "0.5vw" }}
                                            size='small'
                                            value={list?.comment}
                                            disabled
                                        />
                                    </Box>
                                    :
                                    <Box component={'div'}>
                                        <TextField
                                            sx={{ width: "30vw", marginRight: "0.5vw" }}
                                            size='small'
                                            value={values}
                                            multiline
                                            rows={3}
                                            inputProps={{
                                                maxLength: 2000,
                                            }}
                                            onChange={(e) => {
                                                let Data = e.target.value
                                                if(Data !== ''){
                                                    if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                                        patientResultStatus[mainIndex]['data'][subIndex]['result']['value'] = Data
                                                    } else {
                                                        patientResultStatus[mainIndex]['data'][subIndex]['result'] = { value: Data }
                                                    }
                                                    this.setState({
                                                        patientResultStatus
                                                    })
                                                }else{
                                                    patientResultStatus[mainIndex]['data'][subIndex]['result'] = null
                                                    this.setState({ patientResultStatus })
                                                }
                                            }}
                                        />
                                        <TextField
                                            sx={{ width: "30vw", marginTop: "0.5vw" }}
                                            size='small'
                                            value={comment}
                                            placeholder={"Comments"}
                                            inputProps={{
                                                maxLength: 300,
                                            }}
                                            onChange={(e) => {
                                                if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.value) {
                                                    let Data = e.target.value
                                                    if (patientResultStatus[mainIndex]['data'][subIndex]['result']?.comment) {
                                                        patientResultStatus[mainIndex]['data'][subIndex]['result']['comment'] = Data
                                                    } else {
                                                        patientResultStatus[mainIndex]['data'][subIndex]['result']["comment"] = Data
                                                    }
                                                    this.setState({
                                                        patientResultStatus
                                                    })
                                                } else {
                                                    this.setState({
                                                        isErrorMsg: true,
                                                        isErrorMsgText: "Enter Result"
                                                    })
                                                }
                                            }}
                                        />
                                    </Box>
                                }
                            </div> : null
        )
    }

    renderNewResultTable = () => {
        try {
            return (
                <Box component={'div'} border={'1px solid lightgray'} height={'62vh'} overflow={'scroll'}>
                    <Table size='small' stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: '600', fontSize: '0.9vw', backgroundColor: Colors.Background, color: Colors.SecondaryText, width: '20vw' }}>Service</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: '0.9vw', backgroundColor: Colors.Background, color: Colors.SecondaryText, width: '20vw' }}>Test Name</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: '0.9vw', backgroundColor: Colors.Background, color: Colors.SecondaryText, width: '20vw' }}>Reference Range</TableCell>
                                <TableCell sx={{ fontWeight: '600', fontSize: '0.9vw', backgroundColor: Colors.Background, color: Colors.SecondaryText }}>Result</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.patientResultStatus?.length > 0 ? this.state.patientResultStatus?.map((item, index) => (
                                <TableRow sx={{ overflow: 'scroll' }}>
                                    <TableCell colSpan={4} sx={{ padding: '0vw' }}>
                                        <Table>
                                            <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                <Typography>{item?.result_description ? item?.result_description : '-'}</Typography>
                                            </TableCell>

                                            <TableCell sx={{ padding: "0vw" }}>
                                                {item?.data?.map((list, secondIndex) => (
                                                    <Table>
                                                        <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                            <Typography>{list?.test_name ? list?.test_name?.length > 25 ?
                                                                <Tooltip placement='top' title={list?.test_name}>
                                                                    <div>{list?.test_name.slice(0, 20) + '...'}</div>
                                                                </Tooltip> : list?.test_name : '-'}</Typography>
                                                        </TableCell>

                                                        {list.method == "Culture Test Result" ?
                                                            <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                                -
                                                            </TableCell> :
                                                            list?.method === "Lesser and Greater Result" ?
                                                                <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                                    {((list?.normal_range?.length > 0) && Array.isArray(list?.normal_range)) ? list?.normal_range?.map((range, rangeIndex) => (
                                                                        <Table key={rangeIndex}>
                                                                            <TableCell sx={{ padding: '0vw', borderBottom: 'none' }}>
                                                                                {(range?.reference_type + range?.reference_range + range?.unit)?.length > 25 ?
                                                                                    <Tooltip placement='top' title={`${range?.reference_type ? range?.reference_type : ''}  ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}>
                                                                                        <Typography component={'div'}>{`${range?.reference_type ? range?.reference_type : ''}  ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}</Typography>
                                                                                    </Tooltip> :
                                                                                    <Typography>{`${range?.reference_type ? range?.reference_type : ''} ${range?.reference_range ? range?.reference_range : ''} (${range?.unit ? range?.unit : ''})`}</Typography>}
                                                                            </TableCell>
                                                                        </Table>
                                                                    )) : '-'}
                                                                </TableCell> :
                                                                <TableCell sx={{ width: '20vw', borderRight: '1px solid lightgray' }}>
                                                                    {((list?.normal_range?.length > 0) && Array.isArray(list?.normal_range)) ? list?.normal_range?.map((range, rangeIndex) => (
                                                                        <Table key={rangeIndex}>
                                                                            <TableCell sx={{ padding: '0vw', borderBottom: 'none' }}>
                                                                                {(range?.reference_from + range?.reference_to + range?.unit)?.length > 25 ?
                                                                                    <Tooltip placement='top' title={`${range?.reference_from ? range?.reference_from : ''} (${range?.unit ? range?.unit : ''}) to ${range?.reference_to ? range?.reference_to : ''} (${range?.unit ? range?.unit : ''})`}>
                                                                                        <Typography component={'div'}>{`${range?.reference_from ? range?.reference_from : ''} (${range?.unit ? range?.unit : ''}) to ${range?.reference_to ? range?.reference_to : ''} (${range?.unit ? range?.unit : ''})`}</Typography>
                                                                                    </Tooltip> :
                                                                                    <Typography>{`${range?.reference_from ? range?.reference_from : ''} ${range?.unit ? "(" + range?.unit + ")" : ''} ${range?.unit ? "to" : "-"} ${range?.reference_to ? range?.reference_to : ''} ${range?.unit ? "(" + range?.unit + ")" : ''}`}</Typography>}
                                                                            </TableCell>
                                                                        </Table>
                                                                    )) : '-'}
                                                                </TableCell>}

                                                        <TableCell>
                                                            {list?.is_outsource ?
                                                                this.renderSourcePopUp(list, secondIndex) :
                                                                this.resultMethodTypeList(list?.method, index, secondIndex, list?.result, list)}
                                                        </TableCell>
                                                    </Table>
                                                ))}
                                            </TableCell>

                                        </Table>
                                    </TableCell>

                                </TableRow>

                            ))
                                : <TableRow>
                                    <TableCell sx={{ borderBottom: 'none' }}></TableCell>
                                    <TableCell align='center' sx={{ borderBottom: 'none', height: '20vh', margin: 'auto' }}>No Records To Be Shown</TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }}></TableCell>
                                    <TableCell sx={{ borderBottom: 'none' }}></TableCell>
                                </TableRow>}
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.setState({
                isErrorMsg: true,
                isErrorMsgText: error.message
            })
        }
    }

    handleCancel = () => {
        let { patientResultStatus, PrintTestList } = this.state;
        let print_list = patientResultStatus.map(item => (
            item?.data?.length > 0 && item.data.filter(li => li?.result?.value != null).map(test => {
                if (
                    test?.result !== null &&
                    typeof test?.result === "object" &&
                    ("value" in test?.result)
                ) {
                    test["isChecked"] = true;
                    return test; // Keep the test object in the mapped array
                } else {
                    // return null;
                }
            })
        ))
        const initialCheckboxState = print_list.map((group) =>
            group.map((item) => ({
                isChecked: item.isChecked,
                id: item.id,
            }))
        );
        this.setState({
            viewPrintLst: false,
            isPrintClicked: false,
            PrintTestList: print_list,
            checkboxState: initialCheckboxState,
        })
    }

    handleParentCheckboxChange = (groupIndex) => {
        this.setState((prevState) => {
            const updatedState = [...prevState.checkboxState];
            updatedState[groupIndex] = updatedState[groupIndex].map((item) => ({
                ...item,
                isChecked: !prevState.checkboxState[groupIndex][0].isChecked,
            }));

            this.getCheckedIds(); // Invoke getCheckedIds on checkbox change
            return { checkboxState: updatedState };
        });
    };

    handleChildCheckboxChange = (groupIndex, childIndex) => {
        this.setState((prevState) => {
            const updatedState = [...prevState.checkboxState];
            updatedState[groupIndex][childIndex] = {
                ...prevState.checkboxState[groupIndex][childIndex],
                isChecked: !prevState.checkboxState[groupIndex][childIndex].isChecked,
            };

            this.getCheckedIds(); // Invoke getCheckedIds on checkbox change
            return { checkboxState: updatedState };
        });
    };

    getCheckedIds = () => {
        const checkedIds = this.state.checkboxState
            .flat()
            .filter((item) => item.isChecked)
            .map((item) => item.id);
    };

    ShowTestHistory = () => {
        return (
            <TestHistory PatientDetails={this.state.patient_status} />
        )
    }

    render() {
        const { t } = this.props
        let { page, rowsPerPage, showPrintBtn, PrintTestList, viewPrintLst, checkboxState } = this.state
        let states = this.state
        return (
            <>
                <div className='emedhub_result_card'>
                    <div style={{ display: "flex", height: "10vh" }}>
                        <div>
                            <img
                                onClick={() => {
                                    if (this.state.showHistory) {
                                        this.setState({
                                            showHistory: false
                                        })
                                    }
                                    else if (this.state.fromTechnician) {
                                        let { history } = this.props
                                        history.push({ pathname: "/MainHome", state: { selectedScreen: "ForTechnicians", selectedTabID: 3, fromResultSaved: true } })
                                    } else if ((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")) {
                                        let { history } = this.props
                                        history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                                    } else {
                                        if ((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")) {
                                            let { history } = this.props
                                            history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                                        } else {
                                            let { history } = this.props
                                            history.push({ pathname: "/MainHome", state: { selectedScreen: "Status", selectedTabID: 1 } })
                                        }
                                    }
                                }}
                                className='emedhub_back_arrow'
                                src={ImagePaths.LabBackButton.default}
                                role={"button"}
                                alt="back"
                            />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
                            <div style={{ display: "flex", flexDirection: "column", marginTop: "0.5vw", marginLeft: "0.5vw" }}>
                                <label className='emedhub_result_header_title'>{this.state.patient_status?.patient_name + ` (${this.state.patient_status?.patient_age} / ${this.state.patient_status?.patient__gender} )`.toUpperCase()}</label>
                                <label className='emedhub_result_header_text'>{`${t("UHID")} : ${this.state.patient_status?.ip_number ? `${this.state.patient_status?.patient__patient_account_number} (IP No : ${this.state.patient_status?.ip_number})` : this.state.patient_status?.patient__patient_account_number}`}</label>
                                <label className='emedhub_result_header_text'>{`${t("MobileNo")} : ${this.state.patient_status?.patient__mobile_number}`}</label>
                                <label className='emedhub_result_header_text'>{`${"Barcode Number"} : ${this.state.patient_status?.barcode ? this.state.patient_status?.barcode : "-"}`}</label>
                            </div>

                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                {this.state.disableLeft ?
                                    <Autocomplete
                                        size='small'
                                        sx={{ display: this.state.showHistory ? "none" : "block" }}
                                        options={this.state.signatureList ? this.state.signatureList : []}
                                        getOptionLabel={(option) => (`${option?.name} - ${option?.role}`)}
                                        disabled={(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")}
                                        value={this.state?.LeftDrop && Object.keys(this.state?.LeftDrop).length > 0 ? this.state?.LeftDrop : null}
                                        onChange={(event, newValue) => {
                                            states.LeftDrop = newValue
                                            this.setState({ states })
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} style={{ width: "13vw" }} label={this.state.disableLeft} />
                                        )}
                                    /> : null}
                                {this.state.disableCentre ?
                                    <Autocomplete
                                        size='small'
                                        sx={{ display: this.state.showHistory ? "none" : "block" }}
                                        options={this.state.signatureList}
                                        getOptionLabel={(option) => (`${option?.name} - ${option?.role}`)}
                                        disabled={(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")}
                                        value={this.state?.CentreDrop && Object.keys(this.state?.CentreDrop).length > 0 ? this.state?.CentreDrop : null}
                                        onChange={(event, newValue) => {
                                            states.CentreDrop = newValue
                                            this.setState({ states })
                                        }}
                                        style={{ marginLeft: "0.5vw" }}
                                        renderInput={(params) => (
                                            <TextField {...params} style={{ width: "13vw" }} label={this.state.disableCentre} />
                                        )}
                                    /> : null}
                                {this.state.disableRight ?
                                    <Autocomplete
                                        size='small'
                                        sx={{ display: this.state.showHistory ? "none" : "block" }}
                                        options={this.state.signatureList}
                                        getOptionLabel={(option) => (`${option?.name} - ${option?.role}`)}
                                        disabled={(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")}
                                        value={this.state?.RightDrop && Object.keys(this.state?.RightDrop).length > 0 ? this.state?.RightDrop : null}
                                        onChange={(event, newValue) => {
                                            states.RightDrop = newValue
                                            this.setState({ states })
                                        }}
                                        style={{ marginLeft: "0.5vw" }}
                                        renderInput={(params) => (
                                            <TextField {...params} style={{ width: "13vw" }} label={this.state.disableRight} />
                                        )}
                                    /> : null}

                                <Tooltip title={"Print Result"} placement='top' arrow>
                                    <IconButton sx={{ display: this.state.showHistory ? 'none' : "block" }} size="small" disabled={!showPrintBtn} onClick={() => this.setState({ viewPrintLst: true })}>
                                        <img
                                            className='eMed_action_img'
                                            src={showPrintBtn ? ImagePaths.LabPrintIcon.default : ImagePaths.LabPrintIconOff.default}
                                            alt="print"
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={"Patient Test History"} placement='top' arrow>
                                    <IconButton sx={{ display: this.state.showHistory ? 'none' : "block" }} size="small" onClick={() => this.setState({ showHistory: true })}>
                                        <img
                                            className='eMed_action_img'
                                            src={ImagePaths.ResultHistory.default}
                                            alt="history"
                                        />
                                    </IconButton>
                                </Tooltip>

                                {viewPrintLst && PrintTestList.length > 0 ?
                                    <Menu
                                        anchorEl={viewPrintLst}
                                        sx={{ position: 'absolute', top: '9vw', right: "1vw" }}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        PaperProps={{
                                            style: {
                                                maxHeight: 350,
                                                overflowY: "scroll",
                                            },
                                        }}
                                        open={viewPrintLst}
                                        onClose={() => this.handleCancel()}
                                    >
                                        <Box component={'div'} p={'1vw'} sx={{ height: '40vh', overflowY: 'scroll' }}>

                                            {this.state.PrintTestList.map((group, groupIndex) => (
                                                <div key={groupIndex}>
                                                    {(group?.length > 0 && group[0]) ? group[0]?.set_name && (
                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={checkboxState[groupIndex]?.every((item) => item.isChecked)}
                                                                        onChange={() => this.handleParentCheckboxChange(groupIndex)}
                                                                    />
                                                                }
                                                                label={`${group[0]?.set_name}`}
                                                            />

                                                            {group?.length > 0 ? group.map((item, childIndex) => (
                                                                <FormControlLabel
                                                                    sx={{ marginLeft: '1vw' }}
                                                                    key={childIndex}
                                                                    control={
                                                                        <Checkbox
                                                                            checked={checkboxState[groupIndex][childIndex]?.isChecked}
                                                                            onChange={() => this.handleChildCheckboxChange(groupIndex, childIndex)}
                                                                        />
                                                                    }
                                                                    label={`${item?.test_name}`}
                                                                />
                                                            )) : null}
                                                        </FormGroup>
                                                    ) : null}

                                                    {group.length > 0 && group[0] ? !group[0]?.set_name && (
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={checkboxState[groupIndex][0]?.isChecked}
                                                                    onChange={() => this.handleChildCheckboxChange(groupIndex, 0)}
                                                                />
                                                            }
                                                            label={`${group[0]?.test_name}`}
                                                        />
                                                    ) : null}

                                                    {group.length > 0 && group[0] ? <hr /> : null}
                                                </div>
                                            ))}
                                        </Box>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                            <Button
                                                size="small"
                                                sx={{ mr: '1vw', textTransform: 'capitalize' }}
                                                variant='contained'
                                                disabled={this.state.isPrintClicked}
                                                onClick={() => this.getResultPrint()}
                                            >
                                                Print
                                            </Button>
                                            <Button
                                                variant='outlined'
                                                size="small"
                                                sx={{ textTransform: 'capitalize' }}
                                                onClick={() => this.handleCancel()}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>
                                    </Menu> : null}
                            </div>
                        </div>
                    </div>
                    {/* <div className='emedhub_result_table'>
                        <Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell size='small' sx={{ backgroundColor: "#F5F8F7", fontWeight: "600", paddingLeft: "2vw" }}>{t("Service")}</TableCell>
                                    <TableCell size='small' sx={{ backgroundColor: "#F5F8F7", fontWeight: "600", paddingLeft: "9vw" }}>{t("Test Name")}</TableCell>
                                    <TableCell size='small' sx={{ backgroundColor: "#F5F8F7", fontWeight: "600", paddingLeft: "9vw" }}>{t("Reference Range")}</TableCell>
                                    <TableCell size='small' sx={{ backgroundColor: "#F5F8F7", fontWeight: "600", paddingLeft: "9vw" }}>{t("Result")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.patientResultStatus?.length > 0 ?
                                    this.state.patientResultStatus?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((item, index) => (
                                            <TableRow key={item?.id}>
                                                <TableCell sx={{padding:"0vw"}}><div className='emedhub_result_margin' >{item?.result_description}</div></TableCell>
                                                <TableCell sx={{padding:"0vw"}}>
                                                    {item?.data.map((list) => (<div className='emedhub_name_margin range_style' key={list.id}>{list?.test_name}</div>))}
                                                </TableCell>
                                                <TableCell sx={{padding:"0vw"}}>
                                                    {item?.data.map((list) => (
                                                        <div className='emedhub_name_margin range_style' key={list.id}>
                                                            {((list?.normal_range?.range_from) || list?.normal_range?.range_from === 0) ?
                                                                `${list?.normal_range?.range_from} to ${list?.normal_range?.range_to} / ${list?.normal_range?.unit}` :
                                                                <><span style={{ color: "#787276" }}>{t("range")}</span></>
                                                            }</div>))}
                                                </TableCell>
                                                <TableCell sx={{padding:"0vw"}}>
                                                    {item?.data.map((list, i) => (<div className='emedhub_name_margin' key={list.id}>{
                                                        list?.is_outsource ? this.renderSourcePopUp(list, i) : this.resultMethodTypeList(list?.method, index, i, list?.result, list)}
                                                    </div>
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                        )) : <TableRow><div className='emedhub_no_data'>{t('NoRecordsFound')}</div></TableRow>
                                }
                            </TableBody>
                        </Table>
                        <div>
                            <TablePagination
                                component='div'
                                id="table_pagination"
                                rowsPerPageOptions={[10, 20, 30]}
                                count={this.state.patientResultStatus?.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={this.handlePageChange}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </div>
                    </div> */}
                    {this.state.showHistory ? this.ShowTestHistory() : this.renderNewResultTable()}
                </div >

                <div style={{ marginTop: '0.5vw', display: this.state.showHistory ? 'none' : "inline" }}>
                    {(this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered") ? "" :
                        <div className='emedhub_main_button'>
                            <Button
                                variant='contained'
                                sx={{ marginRight: "0.5vw", textTransform: "capitalize" }}
                                emed_tid='Result_SaveChanes_Btn'
                                disabled={this.state.disableBtn}
                                onClick={() => {

                                    this.postPatientResultStatus()
                                }}
                            >{t("SaveChanges")}</Button>
                            {this.state.fromTechnician ? null : <Button
                                emed_tid='Result_FinishReport_Btn'
                                disabled={this.state.disableBtn ? true : (this.state.showPrintBtn || this.state.enableFinishRpt) ? false : true}
                                variant='contained'
                                sx={{ textTransform: "capitalize" }}
                                onClick={() => {
                                    if ((this.state.patient_status.status === "Result Completed") || (this.state.patient_status.status === "Result Delivered")) {
                                        let { history } = this.props
                                        history.push({ pathname: "/MainHome", state: { selectedScreen: "TestReport", selectedTabID: 2 } })
                                    } else {
                                        this.putPatientResultStatus()
                                    }
                                }}
                            >{t("FinishReport")}</Button>}
                        </div>
                    }
                </div>
                {
                    this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : this.state.isSuccessMsg ?
                            <ToastMsg
                                severity={'success'}
                                msg={this.state.isSuccessMsgText}
                                msgPop={this.msgClose.bind(this)}
                            /> : null
                }
            </>
        )
    }
}


export default withTranslation()(Result)